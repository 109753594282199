<div class="div-card">
  <mat-card class="wrimagecard wrimagecard-topimage" (click)="onClick()">
    <div class="wrimagecard-topimage_header">
      <mat-card-header>
        <span class="material-symbols-outlined icon">{{ item.icon }}</span>
      </mat-card-header>
      <h2>{{ item.title }}</h2>
      <mat-card-content class="mb-3">
        <p>
          {{ item.body }}
        </p>
      </mat-card-content>
    </div>
  </mat-card>
  <div class="div-footer">
    <a class="btn-link text-white" mat-raised-button color="primary" (click)="onClick()">
      Accéder <mat-icon class="icon-chevron">chevron_right</mat-icon>
    </a>
  </div>
</div>
