import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  module = environment.webService.server + environment.webService.url + environment.webService.api;
  constructor(private http: HttpClient) {}

  // Export Astr
  exportAstrWithParams(offset: number = 0, limit: number = 15, filters: any = {}, generiqueFiltre: string | null = null) {
    let params = new HttpParams().set('page', offset).set('size', limit);
    generiqueFiltre = generiqueFiltre ?? '';
    let url = this.module + '/export-donnees';
    if (generiqueFiltre != '' && generiqueFiltre !== undefined && generiqueFiltre !== null) {
      url = url + '?' + `filterGenerique=${generiqueFiltre}`;
      return this.http.get<any>(url, { params });
    }
    if (filters.length !== 0 && filters) {
      for (const key in filters) {
        params = params.append(key, filters[key]);
      }
    }
    return this.http.get<any>(this.module + '/export-donnees', { params });
  }

  // Export Fiches Navettes Par Agent
  exportFicheWithParams(offset: number = 0, limit: number = 15, filters: any = {}, generiqueFiltre: string | null = null) {
    let params = new HttpParams().set('page', offset).set('size', limit);
    generiqueFiltre = generiqueFiltre ?? '';
    let url = this.module + '/export-donnees/fiches-navettes';
    if (generiqueFiltre != '' && generiqueFiltre !== undefined && generiqueFiltre !== null) {
      url = url + '?' + `filterGenerique=${generiqueFiltre}`;
      return this.http.get<any>(url, { params });
    }
    if (filters.length !== 0 && filters) {
      for (const key in filters) {
        params = params.append(key, filters[key]);
      }
    }
    return this.http.get<any>(this.module + '/export-donnees/fiches-navettes', { params });
  }

  // Export de contrôle (plage de 3 mois)
  exportControleWithParams(
    offset: number = 0,
    limit: number = 15,
    filters: any = {},
    generiqueFiltre: string | null = null,
    dateDebut: string | null = null,
    dateFin: string | null = null
  ) {
    let params = new HttpParams().set('page', offset).set('size', limit);
    generiqueFiltre = generiqueFiltre ?? '';
    let url = this.module + '/export-donnees';

    if (
      (generiqueFiltre != '' && generiqueFiltre !== undefined && generiqueFiltre !== null) ||
      dateDebut !== undefined ||
      dateFin !== undefined
    ) {
      url = url + '?';
      if (generiqueFiltre != '' && generiqueFiltre !== undefined && generiqueFiltre !== null) {
        url = url + `filterGenerique=${generiqueFiltre}`;
      }
      if (dateDebut !== undefined || dateFin !== undefined) {
        url = url + `&dateRealisationStart=${dateDebut}&dateRealisationEnd=${dateFin}`;
      }
      return this.http.get<any>(url, { params });
    }
    if (filters.length !== 0 && filters) {
      for (const key in filters) {
        params = params.append(key, filters[key]);
      }
    }
    return this.http.get<any>(this.module + '/export-donnees', { params });
  }

  exportwithFilter(filters: any = {}) {
    let params = new HttpParams().set('page', 0).set('size', 20000);

    if (filters.length !== 0 && filters) {
      for (const key in filters) {
        /** Cas particulier : pour faire une requête entre 2 dates exportDateDebut et exportDateFin
         * le back attend qu'on lui envoie 2 fois le paramètre exportDateDebut : il fera une requête between
         * entre les 2 dates
         */
        if (key == 'exportDateFin') {
          params = params.append('exportDateDebut', filters[key]);
        } else {
          params = params.append(key, filters[key]);
        }
      }
    }

    return this.http.get<any>(this.module + '/export-donnees', { params });
  }

  launchJob() {
    let params = new HttpParams();

    return this.http.post<any>(this.module + '/export-donnees/launch-job', { params });
  }

  // Get PDF HS
  exportPdfFiche(idCamp: string, idAgent: string) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.get(this.module + '/export-donnees/' + idCamp + '/fiches-navettes/' + idAgent, httpOptions);
  }

  // Get PDF HS
  getPdfFiche(idCamp: string, idAgent: string) {
    return this.http.get(this.module + '/export-donnees/' + idCamp + '/fiches-navettes/' + idAgent);
  }
}
