import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { ValidationSaisiesService } from 'src/app/services/validation-saisies.service';
import { take } from 'rxjs';
import { DragDropModule } from '@angular/cdk/drag-drop';

export interface Elements {
  position: number;
  Avis_DO: string;
  Motif_DD: string;
  D_Organisateur: string;
  Motif_DO: string;
  avis_DGA: string;
}

@Component({
  selector: 'app-detail-validation',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatDialogModule,
    DragDropModule
  ],
  templateUrl: './detail-validation.component.html',
  styleUrls: ['./detail-validation.component.scss']
})
export class DetailValidationComponent {
  displayedColumns: string[] = ['position', 'Avis_DO', 'Motif_DD', 'D_Organisateur', 'Motif_DO', 'avis_DGA'];
  dataSource2 = [];
  dialogRef: any;
  dataJSON: any[] = [];
  rowData: any;
  dateP = new DatePipe('fr-FR');
  totalLigne: any = '';
  afficheTable: any = null;
  type: string = '';

  tableColumns: string[] = ['Date', 'Heure de validation', 'Avis', 'Valideur', 'motif'];
  dataSource: any[] = [];
  astrainteDataSource: any[] = [];
  constructor(
    private ref: MatDialogRef<DetailValidationComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ValidationService: ValidationSaisiesService,
    private detectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.rowData = this.data;

    this.type = this.rowData?.type;

    console.log('type:' + this.rowData.type);

    if (this.data.type === 'hs') {
      this.getListeValidation();
    } else if (this.data.type == 'astreinte') {
      this.getListeAstreinte();
    }
  }

  closepopup() {
    this.ref.close();
  }
  fermerdialog() {
    this.ref.close();
  }

  getListeValidation() {
    let id = this.rowData.id;

    console.log('id: ' + id);

    if (id != undefined && id != null) {
      this.ValidationService.getValidationDetail(id)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            console.log('getValidationDetail result:', result);
            this.dataJSON = result;
            this.rowData = this.transformData();
            this.dataSource = this.rowData;

            this.afficheTable = result.length > 0 ? true : false;
          },
          error: (error) => {
            this.afficheTable = false;
            console.log('erreur:', error);
          }
        });
    }
  }

  getListeAstreinte() {
    let id = this.rowData.id;

    if (id != undefined && id != null) {
      this.ValidationService.getValidationDetailAstreinte(id)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            console.log('getValidationDetailAstreinte result:', result);
            this.dataJSON = result;
            this.rowData = this.transformData();
            this.dataSource = this.rowData;
            this.afficheTable = result.length > 0 ? true : false;
          },
          error: (error) => {
            this.afficheTable = false;
            console.log('erreur:', error);
          }
        });
    }
  }

  transformData() {
    let dataVD: any[] = [];

    for (let key in this.dataJSON) {
      let dataJ = this.dataJSON[key];
      let _data: any = {};

      _data['dateCreation'] = this.dateP.transform(dataJ?.dateCreation, 'dd/MM/YYYY');
      _data['heurValidation'] = this.dateP.transform(dataJ?.dateCreation, 'H:mm');

      let status_code = dataJ?.statut;
      let status_text = '';
      if (status_code == 'VALIDEES') {
        status_text = 'Avis favorable - Directeur';
      } else if (status_code == 'REFUSEES') {
        status_text = 'Avis défavorable - Directeur';
      } else if (status_code == 'A_VALIDER') {
        status_text = 'Avis non renseigné';
      } else if (status_code == 'VALIDEES_N2') {
        status_text = 'Avis favorable - DG';
      } else if (status_code == 'REFUSEES_N2') {
        status_text = 'Avis défavorable - DG';
      } else if (status_code == 'SAISIE_A_RENOUVELER') {
        status_text = 'Saisie à renouveler';
      } else if (status_code == 'VALIDEES_RESPONSABLE') {
        status_text = 'Avis favorable - Responsable';
      } else if (status_code == 'REFUSEES_RESPONSABLE') {
        status_text = 'Avis défavorable - Responsable';
      } else if (status_code == 'A_VALIDER_RESPONSABLE') {
        status_text = 'Avis non renseigné - Responsable';
      } else if (status_code == 'A_VALIDER_DG') {
        status_text = 'Avis non renseigné - DG';
      }
      _data['statut'] = status_text;

      _data['valideur'] = dataJ?.agent?.nom + ' ' + dataJ?.agent?.prenom;
      _data['motif'] = dataJ?.motifRefus;

      dataVD.push(_data);
    }

    //var result = [{"id":"35675800-47e6-43d5-ab5c-fa381431c036","statut":"VALIDEES","precStatut":"A_VALIDER","dateCreation":"2023-10-27","heureSupplementaireDTO":{"id":"9159be3c-9fed-4af7-8fe3-9a529935cd44","type":null,"statut":null,"dateRealisation":"2023-11-01","dateCreation":"2023-10-27","heureDebut":"00:00:00","heureFin":"20:00:00","duree":null,"evenement":null,"agent":null,"emetteur":null,"campagne":null,"directionOrigine":null,"directionOrganisatrice":null,"serviceFonctionnel":null,"motifRefus":null,"typeHS":null,"motif":null,"createdBy":null,"lastModifiedBy":null,"lastModifiedDate":null},"motifRefus":null,"agent":{"id":"0d840e99-a5b5-4b90-bf18-27ee5af9da5b","nom":"Studia","prenom":"Admin","email":null,"login":null,"numSecSociale":null,"matricule":null,"grade":null,"serviceFonctionnel":null,"direction":null,"profil":null}}]

    return dataVD;
  }
}
