import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DelegationModalComponent } from 'src/app/features/modales-utilisateur/delegation-modal/delegation-modal.component';
import { AlarmConfigModalComponent } from 'src/app/features/modales-utilisateur/alarm-config-modal/alarm-config-modal.component';
import { environment } from 'src/environments/environment';
import { RoleService } from 'src/app/services/role.service';
import { ValidationSaisiesService } from 'src/app/services/validation-saisies.service';
import { Observable, Subscription, filter, take } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Broadcast } from 'src/app/core/services/broadcast.service';
import { CampagneActuService } from 'src/app/features/home-page/campagne-actuelle/campagne-actuelle.service';
import { HeuresSupplémentairesService } from 'src/app/services/heures-supplémentaires.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatBadgeModule, MatMenuModule, MatDialogModule, MatTooltipModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  version: any;
  links: string[] = ['ACCUEIL', 'SAISIES ET CONSULTATION', ' VALIDATION DES SAISIES', 'EXPORT DES DONNÉES', 'ADMINISTRATION'];
  activeLink: string = this.links[0];
  name: string | undefined;
  userId: any;
  roleApplicatif: string | undefined;

  boutonNotifActif: boolean = false;
  userRoles: string[] = [];
  campagneEnCours: boolean = false;
  badgeNotification: number = 0;
  saisieEnAttend: number = 0;
  astrainteEnAttend: number = 0;
  chartActif: boolean | null = null;
  currentAgent$: Observable<any>;
  private subscription: Subscription;

  isDisplayHelp: boolean = false;
  helpUrl = '';

  constructor(
    private router: Router,
    private keycloak: KeycloakService,
    public dialog: MatDialog,
    private roleService: RoleService,
    private ValidationService: ValidationSaisiesService,
    private broadcast: Broadcast,
    private campagneActuService: CampagneActuService,
    private hsService: HeuresSupplémentairesService
  ) {
    let info_features = '';
    if (environment.features.validation_en_saisie) {
      info_features = 'validation étendue';
    }
    if (info_features && info_features.length > 0) {
      info_features = ' (' + info_features + ')';
    }
    this.version = environment.version + info_features;
    this.loadProfile().then((user) => {
      this.name = user?.username;
      console.log('object', user);

      this.userId = user.id;
      console.log('idd', user.id);
    });
    this.subscription = this.broadcast.event$.subscribe((data) => {
      if (data == 'reloadNotification') {
        this.getListeValidation();
      }
    });

    if (environment.features?.menu_aide) {
      this.isDisplayHelp = true;
      this.helpUrl = environment.menu_aide?.url;
    }
  }

  ngOnInit() {
    this.currentAgent$ = this.hsService.loggedAgent.pipe(filter((data) => data));
    // on force la création de la session campagneActuelle en cas où elle n'existera pas
    // pour optimiser il faudrait pouvoir créer cette session avant le chargement du bandeau
    this.campagneActuService.getCampagneActuelle().subscribe((data) => {
      this.verifCampagne();

      this.roleService.getRole().subscribe((roles) => {
        this.userRoles = roles;

        console.log('user roles = ' + JSON.stringify(this.userRoles));

        // activation de l'icone de notification
        let index_role = Object.keys(roles).find(
          (val: any) =>
            roles[val] === 'dhesa-admin' ||
            roles[val] === 'dhesa-dg' ||
            roles[val] === 'dhesa-directeur' ||
            roles[val] === 'dhesa-responsable' ||
            roles[val] === 'dhesa-assistant-responsable'
        );

        this.roleApplicatif = 'inconnu';
        if (this.userRoles != null && this.userRoles.length > 0) {
          this.userRoles.forEach((userRole) => {
            if (userRole != null && userRole.startsWith('dhesa-') && userRole != 'dhesa-user') {
              this.roleApplicatif = userRole;
            }
          });
        }

        if (this.campagneEnCours) {
          if (index_role != undefined) {
            this.boutonNotifActif = true;
            this.getListeValidation();
          }
        }
      });
    });
  }

  // vérifie si une campagne de validation est en cours
  verifCampagne() {
    let campagneActuelle: any = sessionStorage.getItem('campagneActuelle');
    let _campagneActuelle = JSON.parse(campagneActuelle);
    console.log('HeaderComponent campagneActuelle', _campagneActuelle);
    this.campagneEnCours = _campagneActuelle?.type == 'VALIDATION_EN_COURS' ? true : false;

    if (_campagneActuelle?.type == 'SAISIE_EN_COURS' && environment.features.validation_en_saisie) {
      this.campagneEnCours = true;
    }
  }

  // bouton notification
  redirectNotification() {
    this.router.navigate(['/validation-des-saisies'], { queryParams: { isHs: true } });
  }
  redirectNotificationAst() {
    this.router.navigate(['/validation-des-saisies'], { queryParams: { isHs: false } });
  }

  // total des validations en attente
  getListeValidation() {
    // total heures sup à valider
    this.ValidationService.getSaisieEnAttenteWithParams(0, 1)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          console.log('getListeAttente result: ', result);
          let badgeNotification = Number(result.totalRecords);
          this.saisieEnAttend = Number(result.totalRecords);
          // total astreintes à valider
          this.ValidationService.getAvaliderAstrainteWithParams(0, 1)
            .pipe(take(1))
            .subscribe({
              next: (result2) => {
                this.badgeNotification = badgeNotification + Number(result2.totalRecords);
                this.astrainteEnAttend = Number(result2.totalRecords);
              },
              error: (error) => {
                console.log('erreur:', error);
              }
            });
        },
        error: (error) => {
          console.log('erreur:', error);
        }
      });
  }

  loadProfile(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.keycloak
        .isLoggedIn()
        .then((isLoggedIn) => {
          if (isLoggedIn) {
            this.keycloak
              .loadUserProfile()
              .then((data) => {
                resolve(data);
              })
              .catch((error) => {
                console.log(error);
                reject(error);
              });
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  addLink() {
    this.links.push(`Link ${this.links.length + 1}`);
  }
  logout() {
    sessionStorage.removeItem('isLoginAction');
    this.keycloak.logout('');
  }

  modalGererDelegation() {
    this.dialog.open(DelegationModalComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        name: this.name,
        agentId: '',
        idUser: this.userId,
        viewMode: 'user'
      }
    });
  }

  modalAlarmeConfig() {
    this.dialog.open(AlarmConfigModalComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {}
    });
  }

  redirection_accueil(chartActif?: boolean) {
    let _url = this.router.url.split('?');
    let url = _url[0].replace('/', '');

    if (url == 'accueil') {
      this.chartActif = !this.chartActif;
    } else {
      this.chartActif = true;
    }

    this.router.navigate(['/', 'accueil'], { queryParams: { chartActif: this.chartActif } });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
