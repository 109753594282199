import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormBuilder, FormsModule, ReactiveFormsModule, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';

import { BandeauParcourirComponent } from 'src/app/shared/bandeau-parcourir/bandeau-parcourir.component';
import { Observable, take } from 'rxjs';

import { MatChipsModule } from '@angular/material/chips';
import { EvenementsService } from 'src/app/services/evenements.service';
import { AgentsService } from 'src/app/services/agents.service';
import { DateTime } from 'luxon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DirectionService } from 'src/app/services/direction.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FormulaireHSModel, HoraireHS } from 'src/app/models/formulaire-hs.model';
import { DateCampagneRangeService } from 'src/app/core/services/date-campagne-range.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { CustomLuxonDateAdapter } from 'src/app/shared/utils/custom-luxon-date-adapter';
import { MY_FORMATS } from 'src/app/shared/utils/date-pickr-custom-luxon-format';

@Component({
  selector: 'app-heure-sup-direction-origine-form',
  standalone: true,
  imports: [
    BandeauParcourirComponent,
    CommonModule,
    MatTableModule,
    MatRadioModule,
    MatIconModule,
    MatCardModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatAutocompleteModule,
    NgxMaterialTimepickerModule,
    MatSnackBarModule
  ],
  templateUrl: './heure-sup-direction-origine-form.component.html',
  styleUrls: ['./heure-sup-direction-origine-form.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: DateAdapter, useClass: CustomLuxonDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class HeureSupDirectionOrigineFormComponent {
  dateDebutSaisieString = localStorage.getItem('dateDebutSaisie');
  selectedEvent: any = null;
  agentsList: any = [];
  selectedAgents: any[] = [];
  agentFormControl = new FormControl();
  eventFormControl = new FormControl();
  filteredOptions: any = [];
  directionList$!: Observable<any>;
  filteredOptionsEvent: any = [];
  updateEvent: false;
  selectedEventId: string = '';
  totalDureeFormMin: number;
  totalDureeFormStr: string = '00:00';
  heurDifferror: boolean = false;
  erreurChoixAgentCadre: boolean = false;
  erreurAgentsNonEligibles: boolean = false;
  minDate: DateTime | null;
  maxDate: DateTime | null;
  @Input() currentIndex = 0;
  @Input() typeHS: string = 'HS_POUR_DIRECTION_ORIGINE';
  @Input() infosFormulaires: FormulaireHSModel[] = [];
  @Output() heureInvalide = new EventEmitter();
  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private evenementsService: EvenementsService,
    private agentsService: AgentsService,
    private directionService: DirectionService,
    public dialogRef: MatDialogRef<HeureSupDirectionOrigineFormComponent>,
    private dateCampagneRangeService: DateCampagneRangeService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.loadEvenements();
    this.loadAgents();
    this.directionList$ = this.directionService.getAllDirections();
    this.setDatePicker();

    // Les champs obligatoires ne sont pas les mêmes pour les 2 types de HS
    if (this.typeHS == 'HS_POUR_DIRECTION_ORIGINE') {
      this.formHsEvenements = this.formBuilder.group({
        motif: [null, Validators.required],
        evenement: [null],
        directionId: [null],
        hoursFormArray: this.formBuilder.array([
          this.formBuilder.group({
            date: [null, Validators.required],
            heure_debut: [null, Validators.required],
            heure_fin: [null, Validators.required],
            duree: [null, Validators.required]
          })
        ])
      });
    } else {
      // HS Evènement
      this.formHsEvenements = this.formBuilder.group({
        motif: [null],
        evenement: [null, Validators.required],
        directionId: [null, Validators.required],
        hoursFormArray: this.formBuilder.array([
          this.formBuilder.group({
            date: [null, Validators.required],
            heure_debut: [null, Validators.required],
            heure_fin: [null, Validators.required],
            duree: [null, Validators.required]
          })
        ])
      });
    }

    // On charge le formulaire après avoir récupéré les évènements... (callback de loadEvenements())
  }

  agents: any[] = [];
  evenements: any[] = [];

  formHsEvenements: FormGroup;

  loadFormulaire(idx: number) {
    // On recharge les valeurs du formulaire depuis la page correspondante du tableau de données
    // init premier formulaire
    if (this.infosFormulaires.length <= 0) {
      this.infosFormulaires[0] = this.getNewBlankInfosFormulaire();
    }
    // Le formulaire du bon index existe déjà
    if (idx < this.infosFormulaires.length) {
      let currentForm: FormulaireHSModel = this.infosFormulaires[idx];
      this.getFormControlByName('motif').setValue(currentForm.motif);
      this.getFormControlByName('evenement').setValue(currentForm.evenementId);
      let currentFormEvent = this.evenements.filter((o: any) => {
        return o.id == currentForm.evenementId;
      });
      this.eventFormControl.setValue(currentFormEvent[0]);

      if (currentForm.evenementId && currentForm.evenementId.length > 0) {
        this.evenements.forEach((evt) => {
          if (currentForm.evenementId == evt.id) {
            this.getFormControlByName('directionId').setValue(evt.direction?.id);
          }
        });
      }
      this.getFormControlByName('directionId')?.disable();

      this.selectedAgents = currentForm.agents;

      this.loadFormHoraires(currentForm.horaires);
    } else {
      console.log("On ne peut pas charger le formulaire d'idx " + idx + ", il n'existe pas...");
    }
  }

  updateDirectionFromEvt(evt: any) {
    this.erreurAgentsNonEligibles = false;
    if (evt?.option?.value?.id) {
      // report direction de l'évènement
      let evtId = evt.option.value.id;
      this.evenements.forEach((evt) => {
        if (evtId == evt.id) {
          this.getFormControlByName('directionId').setValue(evt.direction?.id);
          this.getFormControlByName('evenement').setValue(this.eventFormControl.getRawValue().id);
        }
      });

      /**
       * Si on a changé d'évènement, on supprime les agents qui ne sont plus valables
       */
      let evtLib = this.getEvenementLibelle(evtId);
      // Si l'evt sélectionné ne concerne pas les élections
      // un cadre (code grade A) ne peut pas être choisi
      if (evtLib != null && !evtLib.toLowerCase().includes('élections') && !evtLib.toLowerCase().includes('elections')) {
        let isAgentErased = false;
        let cleanedSelectedAgents: any[] = [];
        this.selectedAgents.forEach((agent) => {
          if (agent.codeGrade != 'A') {
            cleanedSelectedAgents.push(agent);
          }
          // sinon on sort cet agent cadre
          else {
            isAgentErased = true;
          }
        });
        this.selectedAgents = cleanedSelectedAgents;
        if (isAgentErased) {
          this.agentFormControl.setErrors({ erreurAgentsNonEligibles: true });
          this.erreurAgentsNonEligibles = true;
        }
      }
    } else {
      // raz direction id
      this.getFormControlByName('directionId').setValue('');
    }
  }

  storeFormulaire(idx: number) {
    this.getFormControlByName('directionId')?.enable();
    const formToJson = this.formHsEvenements.value;
    if (idx < this.infosFormulaires.length) {
      let currentForm: FormulaireHSModel = this.infosFormulaires[idx];
      // si formulaire HS Direction : on utilise la saisie du formulaire direcement
      if (this.typeHS == 'HS_POUR_DIRECTION_ORIGINE') {
        currentForm.motif = formToJson.motif;
      } else {
        // sinon on utilise le libellé de l'évènement
        currentForm.motif = this.getEvenementLibelle(formToJson.evenement);
      }
      currentForm.evenementId = formToJson.evenement;
      currentForm.directionOrganisatriceId = formToJson.directionId;
      currentForm.agents = this.selectedAgents;
      currentForm.horaires = [];
      if (formToJson.hoursFormArray) {
        for (let currentHour of formToJson.hoursFormArray) {
          currentForm.horaires.push({
            dateRealisation: currentHour.date,
            heureDebut: currentHour.heure_debut,
            heureFin: currentHour.heure_fin,
            duree: currentHour.duree
          });
        }
      }
    }
  }

  loadFormHoraires(horaires: HoraireHS[]) {
    let formHoraires = this.getFormArrayByName('hoursFormArray');
    // on vire toutes les heures...
    for (let i = formHoraires.length; i > 0; i--) {
      formHoraires.removeAt(i - 1);
    }
    // ... pour les remplacer par les nouvelles
    if (horaires.length > 0) {
      for (const currentHoraire of horaires) {
        this.hours.push(
          this.formBuilder.group({
            date: [currentHoraire.dateRealisation, Validators.required],
            heure_debut: [currentHoraire.heureDebut, Validators.required],
            heure_fin: [currentHoraire.heureFin, Validators.required],
            duree: [currentHoraire.duree, Validators.required]
          })
        );
      }
      this.recomputeTotalHS();
    }
    // si les heures sont vides, on remet une ligne vide prête à être saisie
    if (horaires.length <= 0) {
      this.hours.push(
        this.formBuilder.group({
          date: [null, Validators.required],
          heure_debut: [null, Validators.required],
          heure_fin: [null, Validators.required],
          duree: [null, Validators.required]
        })
      );
    }
  }

  recomputeTotalHS() {
    if (this.hours && this.hours.length > 0) {
      let accuTotalHS = 0;
      const formToJson = this.formHsEvenements.value;
      if (formToJson.hoursFormArray) {
        for (const currentHour of formToJson.hoursFormArray) {
          let nbHour = 0;
          let nbMin = 0;
          let dureeStr = '' + currentHour.duree;
          if (dureeStr && dureeStr.length >= 5) {
            nbHour = Number(dureeStr.substring(0, 2));
            nbMin = Number(dureeStr.substring(3, 5));
            accuTotalHS += nbHour * 60 + nbMin;
          }
        }
      }

      this.totalDureeFormMin = accuTotalHS;

      const totalHours = Math.floor(this.totalDureeFormMin / 60);
      const totalMinutes = this.totalDureeFormMin % 60;

      const formatHour = totalHours.toString().padStart(2, '0');
      const formatMin = totalMinutes.toString().padStart(2, '0');
      this.totalDureeFormStr = `${formatHour}:${formatMin}`;
    } else {
      this.totalDureeFormMin = 0;
      this.totalDureeFormStr = '00:00';
    }
  }

  getNewBlankInfosFormulaire() {
    return {
      typeHS: this.typeHS,
      motif: '',
      evenementId: null,
      directionOrganisatriceId: null,
      horaires: [],
      agents: []
    };
  }

  /**
   * Permet de récupérer les infos des formulaires.
   * Ne pas oublier de récupérer les données actuellement affichées dans le formulaire
   */
  getCurentInfosFormulaires(): FormulaireHSModel[] {
    // Transfert du formulaire actuellement affiché vers le FormulaireHSModel
    this.storeFormulaire(this.currentIndex);

    return this.infosFormulaires;
  }

  loadEvenements() {
    this.evenementsService
      .getAllEvenements()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.evenements = data;
          let selectedEvent = data.filter((o: any) => {
            return o.id == localStorage.getItem('event');
          });
          this.eventFormControl.setValue(selectedEvent[0]);
          this.filteredOptionsEvent = [...this.evenements];
          this.loadFormulaire(this.currentIndex);
        }
      });
  }
  loadAgents() {
    if (this.typeHS == 'HS_POUR_DIRECTION_ORIGINE') {
      // Seulement les agents de la même direction que moi
      this.agentsService
        .getMaDirectionAgents()
        .pipe(take(1))
        .subscribe((data) => {
          if (data) {
            this.agentsList = data;
            this.filteredOptions = [...this.agentsList];
          }
        });
    } else {
      // Tous les agents
      this.agentsService
        .getAllAgents()
        .pipe(take(1))
        .subscribe((data) => {
          if (data) {
            this.agentsList = data;
            this.filteredOptions = [...this.agentsList];
          }
        });
    }
  }

  filter(event: any): void {
    const value = event.target.value;
    if (typeof value !== 'string') {
      this.filteredOptions = [];
      return;
    }
    const filterValue = value.toLowerCase().split(' ').join('');
    this.filteredOptions = this.agentsList.filter((agent: any) =>
      (agent.nom + agent.prenom + '|' + agent.direction?.libelle + '|' + agent.serviceFonctionnel?.libelle)
        .toLowerCase()
        .split(' ')
        .join('')
        .includes(filterValue)
    );
  }
  filterEvent(event: any): void {
    const value = event.target.value;
    if (typeof value !== 'string') {
      this.filteredOptionsEvent = [];
      return;
    }
    const filterValue = value.toLowerCase().split(' ').join('');
    this.filteredOptionsEvent = this.evenements.filter((event: any) =>
      event.libelle.toLowerCase().split(' ').join('').includes(filterValue)
    );
  }
  displayFn(agent: any): string {
    return agent ? `${agent.nom} ${agent.prenom}` : '';
  }
  displayFnEvent(evenement: any) {
    if (evenement) {
      localStorage.setItem('event', evenement.id);
    }
    return evenement ? `${evenement.libelle}` : '';
  }
  // recherche la ligne data correspondant à l'id
  searchAgentIndex(id: number) {
    let data = this.agentsList;
    let row = Object.keys(data).find((k) => data[k].id === id);
    let index = Number(row);

    return index;
  }

  delete_agent(agent: string) {
    const index = this.selectedAgents.indexOf(agent);
    if (index >= 0) {
      this.selectedAgents.splice(index, 1);
    }
  }

  get hours(): FormArray {
    return this.formHsEvenements.get('hoursFormArray') as FormArray;
  }
  get evenement(): FormControl {
    return this.formHsEvenements.get('evenement') as FormControl;
  }

  getFormControlByName(ctrlName: string): FormControl {
    return this.formHsEvenements.get(ctrlName) as FormControl;
  }
  getFormArrayByName(ctrlName: string): FormArray {
    return this.formHsEvenements.get(ctrlName) as FormArray;
  }

  add_inputs(date?: string, heure_debut?: string, heure_fin?: string, duree?: string) {
    this.hours.push(
      this.formBuilder.group({
        date: [null, Validators.required],
        heure_debut: [null, Validators.required],
        heure_fin: [null, Validators.required],
        duree: [null, Validators.required]
      })
    );
  }

  remove_inputs(index: number) {
    if (this.hours.length > 1) {
      this.hours.removeAt(index);
    } else {
      this.hours.patchValue([{ date: null, heure_debut: null, heure_fin: null, duree: null }]);
    }
    this.recomputeTotalHS();
  }

  add_agent(event: any) {
    this.erreurChoixAgentCadre = false;
    if (event) {
      let selectedAgent = event.option.value;
      const agents = this.agentsList.filter((x: { id: string }) => x.id == selectedAgent.id);
      if (agents && agents.length > 0) {
        const selectedAgent = agents[0];

        // formulaire de type HS_EVENEMENT
        // Si l'évènement est =/= Elections on ne peut pas ajouter un cadre (code grade = A)
        if (this.eventFormControl.getRawValue() != null) {
          let evtId = this.eventFormControl.getRawValue().id;
          let evtLib = this.getEvenementLibelle(evtId);
          // Si l'evt sélectionné ne concerne pas les élections
          // un cadre (code grade A) ne peut pas être choisi
          if (evtLib != null && !evtLib.toLowerCase().includes('élections') && !evtLib.toLowerCase().includes('elections')) {
            if (selectedAgent.codeGrade == 'A') {
              this.agentFormControl.setValue(null);
              this.agentFormControl.setErrors({ erreurChoixAgentCadre: true });
              this.filteredOptions = [...this.agentsList];
              this.erreurChoixAgentCadre = true;
              return;
            }
          }
        }
        // formulaire de type HS_POUR_DIRECTION_ORIGINE (ne contient pas de menu évènement)
        else {
          // un cadre (code grade A) ne peut pas être choisi
          if (selectedAgent.codeGrade == 'A') {
            this.agentFormControl.setValue(null);
            this.agentFormControl.setErrors({ erreurChoixAgentCadre: true });
            this.filteredOptions = [...this.agentsList];
            this.erreurChoixAgentCadre = true;
            return;
          }
        }

        if (selectedAgent && !this.selectedAgents.includes(selectedAgent)) this.selectedAgents.push(selectedAgent);
      }
      this.agentFormControl.setValue(null);
      this.filteredOptions = [...this.agentsList];
    }
  }

  changedurationHs($event: any, i: number) {
    const commonDate = DateTime.now();
    const startDateTime = DateTime.fromFormat(
      `${commonDate.toISODate()}T${this.hours.at(i).get('heure_debut')?.value}`,
      "yyyy-MM-dd'T'HH:mm"
    );
    let endDateTime = DateTime.fromFormat(`${commonDate.toISODate()}T${this.hours.at(i).get('heure_fin')?.value}`, "yyyy-MM-dd'T'HH:mm");
    if (startDateTime.isValid && endDateTime.isValid) {
      // Si l'heure de fin est antérieure à l'heure de début, ajoute un jour à l'heure de fin
      if (endDateTime <= startDateTime) {
        this.heurDifferror = true;
        this.heureInvalide.emit(true);
        const diff = startDateTime.diff(endDateTime, ['hours', 'minutes']);
        this.hours.at(i).get('duree')?.setValue(diff.toFormat('hh:mm'));
      } else {
        this.heurDifferror = true;
        this.heureInvalide.emit(true);

        const diff = endDateTime.diff(startDateTime, ['hours', 'minutes']);
        let value = diff.toFormat('hh:mm').replace('-', '');
        this.hours.at(i).get('duree')?.setValue(diff.toFormat(value));
      }
    }
    // Calcule la différence de temps

    this.recomputeTotalHS();
  }
  differenceTime(startTime: string, endTime: string): string {
    const start = startTime.split(':').map(Number);
    const end = endTime.split(':').map(Number);
    const startMinutes = start[0] * 60 + start[1];
    let endMinutes = end[0] * 60 + end[1]; //to check
    if (endMinutes < startMinutes) {
      endMinutes += 24 * 60;
    }
    const diff = endMinutes - startMinutes;
    const hours = Math.floor(diff / 60);
    const minutes = diff % 60;
    const formatHour = hours.toString().padStart(2, '0');
    const formatMin = minutes.toString().padStart(2, '0');
    return `${formatHour}:${formatMin}`;
  }
  updateDuration(index: number) {
    const startTime = this.hours.at(index).get('heure_debut')?.value;
    const endTime = this.hours.at(index).get('heure_fin')?.value;
    if (startTime && endTime) {
      const duration = this.differenceTime(startTime, endTime);
      this.hours.at(index).get('duree')?.setValue(duration);
    }
  }
  totaldurationHs() {
    let totalDuratuionMinutes = 0;
    for (const hourgroup of this.hours.controls) {
      const startTime = hourgroup.get('heure_debut')?.value;
      const endTime = hourgroup.get('heure_fin')?.value;

      if (startTime && endTime) {
        //to test
        const start = new Date(`1970-01-01T${startTime}`);
        const end = new Date(`1970-01-01T${endTime}`);
        if (end < start) {
          end.setDate(end.getDate() + 1);
        }
        const duration = (end.getTime() - start.getTime()) / (1000 * 60);
        totalDuratuionMinutes += duration;
      }
    }
    const totalHours = Math.floor(totalDuratuionMinutes / 60);
    const totalMinutes = totalDuratuionMinutes % 60;

    const formatHour = totalHours.toString().padStart(2, '0');
    const formatMin = totalMinutes.toString().padStart(2, '0');
    return `${formatHour}:${formatMin}`;
  }

  /**
   * Bandeau : page suivante
   */
  formulaireSuivant() {
    if (this.currentIndex < this.infosFormulaires.length - 1) {
      this.storeFormulaire(this.currentIndex);
      this.currentIndex++;
      this.loadFormulaire(this.currentIndex);
    }
  }

  /**
   * Bandeau : page précédente
   */
  formulairePrecedent() {
    if (this.currentIndex > 0) {
      this.storeFormulaire(this.currentIndex);
      this.currentIndex--;
      this.loadFormulaire(this.currentIndex);
    }
  }

  /**
   * Bandeau : nouveau formulaire
   */
  formulaireNew() {
    // ajout nouveau formulaire, et on y va directement
    this.infosFormulaires.push(this.getNewBlankInfosFormulaire());
    this.eventFormControl.setValue(null);
    this.getFormControlByName('directionId').setValue(null);

    this.storeFormulaire(this.currentIndex);
    this.currentIndex = this.infosFormulaires.length - 1;
    this.loadFormulaire(this.currentIndex);
  }

  /**
   * Bandeau : enlever formulaire
   */
  formulaireDelete() {
    if (this.infosFormulaires.length > 0) {
      // pas besoin de store, on va supprimer ce formulaire
      this.infosFormulaires.splice(this.currentIndex, 1);
      // passage page précédente
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
      // sinon on était déjà à la première page, l'index est déjà ok
      this.loadFormulaire(this.currentIndex);
    }
  }

  /**
   * Formulaire entièrement valide ?
   */
  isFormulaireValid(): boolean {
    return this.formHsEvenements.valid && this.selectedAgents && this.selectedAgents.length > 0;
  }

  /**
   * Récupération du libellé d'un évènement à partir de son ID
   */
  getEvenementLibelle(evtId: string): string {
    if (!evtId) {
      return '';
    }
    if (this.evenements) {
      for (let evt of this.evenements) {
        if (evt.id == evtId) {
          return evt.libelle;
        }
      }
    }
    // pas trouvé ? On renvoie l'evtId
    return evtId;
  }

  rangeFilter = (d: null): boolean => {
    return this.dateCampagneRangeService.rangeHandler(d);
  };

  // date min et max du date picker
  setDatePicker() {
    this.minDate = this.dateCampagneRangeService.minDate();
    this.maxDate = this.dateCampagneRangeService.maxDate();
  }
}
