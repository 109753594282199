<div style="height: 8px; background-color: rgba(16, 46, 94, 0.982)" class="col-12"></div>
<mat-card-header class="" style="cursor: grabbing" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="row col-md-12">
    <div class="col-6">
      <mat-card-title><h2 class="pl-20">Exporter</h2></mat-card-title>
    </div>

    <div class="col-md-6 text-end mb-2 mr-0 ml-auto gx-0">
      <button (click)="cancelDialog()" class="py-0" mat-icon-button aria-label="Fermer la modale">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>
<div mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row mb-20">
      <mat-form-field class="col-5">
        <mat-label>Format </mat-label>
        <mat-select formControlName="format" (selectionChange)="updateValidity()">
          <mat-option *ngFor="let format of formats" [value]="format.value">
            {{ format.value }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls['format'].hasError('required')"> Le format est obligatoire </mat-error>
      </mat-form-field>
    </div>
    <div class="row" *ngIf="exportParCampagne">
      <div class="col-md-12">
        <div class="row">
          <mat-form-field floatLabel="always" class="field-filtre field-filtre-text">
            <mat-label>Campagne </mat-label>
            <input
              #autoCompleteCamp
              type="text"
              placeholder="Campagne"
              aria-label="Campagne"
              matInput
              name="item"
              [matAutocomplete]="autoCampagne"
              formControlName="campagne"
              (input)="campagneFilter($event)"
              [(ngModel)]="val_ft_campagne"
            />
            <mat-autocomplete
              #autoCampagne="matAutocomplete"
              hideSingleSelectionIndicator
              requireSelection
              [displayWith]="campagnedisplayFn"
            >
              <mat-option *ngFor="let campagne of campagneList" [value]="campagne">{{ campagne.nom }}</mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="form.controls['campagne'].hasError('required')"> La campagne est obligatoire </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <mat-dialog-actions align="end">
      <button mat-stroked-button class="mr-5 bouton-fermer" [mat-dialog-close]="true">Annuler</button>
      <button mat-flat-button color="primary" cdkFocusInitial type="submit" [disabled]="!this.form.valid">Valider</button>
    </mat-dialog-actions>
  </form>
  <h5 class="mt-4">Les éléments marqués d'une étoile (*) sont obligatoires</h5>
</div>
