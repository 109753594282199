import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class DateCampagneRangeService {
  rangeHandler = (_date: DateTime | null): boolean => {
    let dateDebut = localStorage.getItem('dateDebutSaisie');
    let dateFin = localStorage.getItem('dateFinSaisie');
    if (!_date || !dateDebut || !dateFin) {
      return true;
    }

    let day_fin = DateTime.fromISO(dateFin).setLocale('fr').day;
    const dateTimeDebut = DateTime.fromISO(dateFin).plus({ month: -3 }).set({ day: 1 });
    const dateTimeFin = DateTime.fromISO(localStorage.getItem('dateFinSaisie') + '')
      .plus({ month: 1 })
      .set({ day: 1 })
      .plus({ day: -1 });

    return _date >= dateTimeDebut && _date <= dateTimeFin;
  };

  minDate = (): DateTime | null => {
    let dateDebut = localStorage.getItem('dateDebutSaisie');
    let dateFin = localStorage.getItem('dateFinSaisie');
    if (!dateDebut || !dateFin) {
      return null;
    }

    let day_fin = DateTime.fromISO(dateFin).setLocale('fr').day;
    let dateTimeDebut = DateTime.fromISO(dateFin).plus({ month: -3 }).set({ day: 1 });

    return dateTimeDebut;
  };

  maxDate = (): DateTime | null => {
    let dateFin = localStorage.getItem('dateFinSaisie');
    if (!dateFin) {
      return null;
    }

    let dateTimeFin = DateTime.fromISO(dateFin).plus({ month: 1 }).set({ day: 1 }).plus({ day: -1 });

    return dateTimeFin;
  };
}
