<app-ariane-nav [label]="arianeLabel" [icon]="arianeIcon"></app-ariane-nav>
<mat-divider class="my-3"></mat-divider>

<div class="div-conteneur h-10">
  <div class="list-group-item list-group-item-action d-flex div-group-card">
    <div class="div-card-row">
      <div *ngFor="let item of cards" [ngClass]="{ 'div-card': item.show, 'card-hidden': !item.show }">
        <app-card *ngIf="item.show" [item]="item" (onClickEmit)="navigateToCard(item)"></app-card>
      </div>
    </div>
  </div>
</div>
