import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { GestionCampagneComponent } from '../gestion-campagne/gestion-campagne.component';
import { CardComponent } from '../home-page/card/card.component';
import { RoleService } from 'src/app/services/role.service';
import { Broadcast } from 'src/app/core/services/broadcast.service';
import { ArianeNavComponent } from 'src/app/layout/ariane-nav/ariane-nav.component';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-section-administration',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatButtonModule, GestionCampagneComponent, CardComponent, ArianeNavComponent, MatDividerModule],
  templateUrl: './section-administration.component.html',
  styleUrls: ['./section-administration.component.scss']
})
export class SectionAdministrationComponent implements OnInit {
  arianeLabel: string = 'Administration';
  arianeIcon: string = 'settings';
  public cards = [
    {
      icon: 'calendar_month',
      title: 'Gestion des campagnes',
      body: "Connaître les périodes d'ouverture et de fermeture des campagnes de saisie/modification/consultation et de validation des heures supplémentaires et astreintes ",
      link: 'GESTION DES CAMPAGNES',
      routerLink: 'administration/gestion-des-campagnes',
      requiredRoles: [
        'dhesa-assistant-cds',
        'dhesa-gestionnaire-paie',
        'dhesa-dg',
        'dhesa-directeur',
        'dhesa-responsable',
        'dhesa-assistant-responsable',
        'dhesa-assistant-directeur'
      ], //only admin
      show: false
    },
    {
      icon: 'Person',
      title: 'Gestion des délégations',
      body: 'Accéder à la gestion des délégations pour chaque utilisateur',
      link: 'GESTION UTILISATEURS',
      routerLink: 'administration/gestion-delegations',
      requiredRoles: [
        'dhesa-assistant-cds',
        'dhesa-gestionnaire-paie',
        'dhesa-dg',
        'dhesa-directeur',
        'dhesa-responsable',
        'dhesa-assistant-responsable',
        'dhesa-assistant-directeur'
      ], //only admin
      show: false
    },
    {
      icon: 'celebration',
      title: 'Gestion des évènements',
      body: 'Accéder au référentiel des évènements',
      link: 'GESTION EVENEMENTS',
      routerLink: 'administration/evenements',
      requiredRoles: [
        'dhesa-assistant-cds',
        'dhesa-gestionnaire-paie',
        'dhesa-dg',
        'dhesa-directeur',
        'dhesa-responsable',
        'dhesa-assistant-responsable',
        'dhesa-assistant-directeur'
      ], //only admin
      show: false
    },
    {
      icon: 'view_list',
      title: 'Gestion des services',
      body: 'Accéder au référentiel des services. Les Services sont créées automatiquement au besoin lors de la mise à jour des agents.',
      link: 'GESTION SERCICES',
      routerLink: 'administration/services',
      requiredRoles: [
        'dhesa-assistant-cds',
        'dhesa-gestionnaire-paie',
        'dhesa-dg',
        'dhesa-directeur',
        'dhesa-responsable',
        'dhesa-assistant-responsable',
        'dhesa-assistant-directeur'
      ], //only admin
      show: false
    },
    {
      icon: 'border_all',
      title: 'Gestion des directions',
      body: 'Accéder au référentiel des directions. Les directions sont créées automatiquement au besoin lors de la mise à jour des agents.',
      link: 'GESTION DIRECTIONS',
      routerLink: 'administration/directions',
      requiredRoles: [
        'dhesa-assistant-cds',
        'dhesa-gestionnaire-paie',
        'dhesa-dg',
        'dhesa-directeur',
        'dhesa-responsable',
        'dhesa-assistant-responsable',
        'dhesa-assistant-directeur'
      ], //only admin
      show: false
    },
    {
      icon: 'system_update_alt',
      title: 'Suivi des imports et exports',
      body: 'Suivre les traitements périodiques d’importation du référentiel',
      link: 'SUIVIE IMPORTS',
      routerLink: 'administration/suivie-imports',
      requiredRoles: [
        'dhesa-assistant-cds',
        'dhesa-gestionnaire-paie',
        'dhesa-dg',
        'dhesa-directeur',
        'dhesa-responsable',
        'dhesa-assistant-responsable',
        'dhesa-assistant-directeur'
      ], //only admin
      show: false
    },
    {
      icon: 'settings_applications',
      title: 'Paramètres de configuration',
      body: 'Accéder aux paramètres de configuration',
      link: 'PARAMETRES',
      routerLink: 'administration/parametres_de_configurations',
      requiredRoles: [
        'dhesa-assistant-cds',
        'dhesa-gestionnaire-paie',
        'dhesa-dg',
        'dhesa-directeur',
        'dhesa-responsable',
        'dhesa-assistant-responsable',
        'dhesa-assistant-directeur'
      ], //only admin
      show: false
    },
    {
      icon: 'history',
      title: 'Visualisation des saisies HS / Astreintes',
      body: 'Accéder aux données relatives aux campagnes archivées et non archivées',
      link: 'SAISIES ARCHIVEES',
      routerLink: 'administration/archives-saisies',
      requiredRoles: [
        'dhesa-assistant-cds',
        'dhesa-gestionnaire-paie',
        'dhesa-dg',
        'dhesa-directeur',
        'dhesa-responsable',
        'dhesa-assistant-responsable',
        'dhesa-assistant-directeur'
      ], //only admin
      show: false
    },
    {
      icon: 'calendar_today',
      title: 'Jours fériés',
      body: 'Accéder au référentiel des jours fériés.',
      link: 'JOURS FERIES',
      routerLink: 'administration/jours-feries',
      requiredRoles: [
        'dhesa-assistant-cds',
        'dhesa-gestionnaire-paie',
        'dhesa-dg',
        'dhesa-directeur',
        'dhesa-responsable',
        'dhesa-assistant-responsable',
        'dhesa-assistant-directeur'
      ], //only admin
      show: false
    }
  ];

  userRoles: string[] = [];
  constructor(
    private redirection: Broadcast,
    private router: Router,
    private RoleService: RoleService
  ) {}
  ngOnInit() {
    this.RoleService.getRole().subscribe((roles) => {
      this.userRoles = roles;
      this.accessToCard();
    });
  }
  navigateToCard(section: any) {
    this.router.navigateByUrl(section.routerLink);

    this.redirection.broadcastEvent(section.link);
  }
  accessToCard() {
    this.cards.forEach((card) => {
      const hasRole = card.requiredRoles?.some((role) => this.userRoles.includes(role));
      card.show = !hasRole;
      // Cas particulier du Directeur DRH
      if (card.routerLink == 'administration/archives-saisies' && this.userRoles.includes('dhesa-directeur-drh')) {
        card.show = true;
      }
    });
  }
}
