import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenu, MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DelegationModalComponent } from 'src/app/features/modales-utilisateur/delegation-modal/delegation-modal.component';
import { AlarmConfigModalComponent } from 'src/app/features/modales-utilisateur/alarm-config-modal/alarm-config-modal.component';
import { NotificationsModalComponent } from 'src/app/features/modales-utilisateur/notifications-modal/notifications-modal.component';
import { environment } from 'src/environments/environment';
import { RoleService } from 'src/app/services/role.service';
import { ValidationSaisiesService } from 'src/app/services/validation-saisies.service';
import { Observable, Subscription, filter, take } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Broadcast } from 'src/app/core/services/broadcast.service';
import { CampagneActuService } from 'src/app/features/home-page/campagne-actuelle/campagne-actuelle.service';
import { HeuresSupplémentairesService } from 'src/app/services/heures-supplémentaires.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatBadgeModule, MatMenuModule, MatDialogModule, MatTooltipModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @ViewChild('menuTrigger') matMenuTrigger!: MatMenuTrigger;

  version: any;
  links: string[] = ['ACCUEIL', 'SAISIES ET CONSULTATION', ' VALIDATION DES SAISIES', 'EXPORT DES DONNÉES', 'ADMINISTRATION'];
  activeLink: string = this.links[0];
  name: string | undefined;
  userId: any;
  roleApplicatif: string | undefined;
  moi: any;
  appLogo: string = environment.logo_url;
  appTitre: string = environment.titre_app;

  boutonNotifActif: boolean = false;
  userRoles: string[] = [];
  campagneEnCours: boolean = false;
  badgeNotification: number = 0;
  saisieEnAttend: number = 0;
  astrainteEnAttend: number = 0;
  chartActif: boolean | null = null;
  currentAgent$: Observable<any>;
  private subscription: Subscription;

  isDisplayHelp: boolean = false;
  helpUrl = '';

  /* pour n'initialiser qu'une seule fois le compteur */
  /* ce flag est reset à chaque demande de refresh (action de création/validation/refus) */
  isBadgeInitiated = false;

  constructor(
    private router: Router,
    private keycloak: KeycloakService,
    public dialog: MatDialog,
    private roleService: RoleService,
    private ValidationService: ValidationSaisiesService,
    private broadcast: Broadcast,
    private campagneActuService: CampagneActuService,
    private hsService: HeuresSupplémentairesService
  ) {
    let info_features = '';
    if (environment.features.validation_en_saisie) {
      info_features = 'validation étendue';
    }
    if (info_features && info_features.length > 0) {
      info_features = ' (' + info_features + ')';
    }
    this.version = environment.version + info_features;
    this.loadProfile().then((user) => {
      this.name = user?.username;
      console.log('object', user);
      this.moi = user;

      this.userId = user.id;
      console.log('idd', user.id);
    });
    this.subscription = this.broadcast.event$.subscribe((data) => {
      if (data == 'reloadNotification') {
        console.log('Demande de reloadNotification');
        // Pour forcer le recalcul
        this.isBadgeInitiated = false;
        this.getListeValidation();
      }
    });

    if (environment.features?.menu_aide) {
      this.isDisplayHelp = true;
      this.helpUrl = environment.menu_aide?.url;
    }
  }

  ngOnInit() {
    this.currentAgent$ = this.hsService.loggedAgent.pipe(filter((data) => data));

    this.currentAgent$.subscribe((agent) => {
      sessionStorage.setItem('isDRH', 'false');
      if (agent?.direction?.libelle) {
        let dirLibelle = agent?.direction?.libelle;
        if (dirLibelle.startsWith('DRH')) {
          console.log('Stockage isDRH = true');
          sessionStorage.setItem('isDRH', 'true');

          setTimeout(() => {
            this.broadcast.broadcastEvent('loadedProfilDrh');
          }, 150);
        }
      }
    });

    // on force la création de la session campagneActuelle en cas où elle n'existera pas
    // pour optimiser il faudrait pouvoir créer cette session avant le chargement du bandeau
    this.campagneActuService.getCampagneActuelle().subscribe((data) => {
      this.verifCampagne();

      this.roleService.getRole().subscribe((roles) => {
        this.userRoles = roles;

        // DRH ?
        if (sessionStorage.getItem('isDRH') == 'true' && this.userRoles) {
          console.log('Ajout du rôle dhesa-directeur-drh');
          if (!this.userRoles.includes('dhesa-directeur-drh')) {
            this.userRoles.push('dhesa-directeur-drh');
          }
        } else {
          console.log('Pas de rôle dhesa-directeur-drh');
        }

        console.log('user roles = ' + JSON.stringify(this.userRoles));

        // activation de l'icone de notification
        let index_role = Object.keys(roles).find(
          (val: any) =>
            roles[val] === 'dhesa-admin' ||
            roles[val] === 'dhesa-dg' ||
            roles[val] === 'dhesa-directeur' ||
            roles[val] === 'dhesa-responsable' ||
            roles[val] === 'dhesa-assistant-responsable' ||
            roles[val] === 'dhesa-assistant-directeur'
        );

        this.roleApplicatif = 'inconnu';
        if (this.userRoles != null && this.userRoles.length > 0) {
          this.userRoles.forEach((userRole) => {
            if (userRole != null && userRole.startsWith('dhesa-') && userRole != 'dhesa-user') {
              this.roleApplicatif = userRole;
            }
          });
        }

        if (this.campagneEnCours) {
          if (index_role != undefined) {
            this.boutonNotifActif = true;
            this.getListeValidation();
          }
        }
      });
    });
  }

  // vérifie si une campagne de validation est en cours
  verifCampagne() {
    let campagneActuelle: any = sessionStorage.getItem('campagneActuelle');
    let _campagneActuelle = JSON.parse(campagneActuelle);
    console.log('HeaderComponent campagneActuelle', _campagneActuelle);
    this.campagneEnCours = _campagneActuelle?.type == 'VALIDATION_EN_COURS' ? true : false;

    if (_campagneActuelle?.type == 'SAISIE_EN_COURS' && environment.features.validation_en_saisie) {
      this.campagneEnCours = true;
    }
  }

  // bouton notification
  redirectNotification() {
    this.router.navigate(['/validation-des-saisies'], { queryParams: { isHs: true } });
  }
  redirectNotificationAst() {
    this.router.navigate(['/validation-des-saisies'], { queryParams: { isHs: false } });
  }

  // total des validations en attente
  getListeValidation() {
    // si le badge a déjà été calculé on ne refait pas la requête
    if (this.isBadgeInitiated) {
      return;
    }
    console.log('Calcul cloche, appel getListeValidation');

    // On lève le flag ASAP pour éviter de recevoir une autre demande de calcul rapprochée
    // Si la récupération ci-dessous échoue, le badge sera mal initialisé jusqu'à la prochaine
    // validation/refus mais ce n'est pas grave
    this.isBadgeInitiated = true;
    /**
     * TODO : remplacer ces 2 appels consécutifs par un appel unique optimisé
     * qui renvoie directement la valeur du badge
     */
    // total heures sup à valider
    this.ValidationService.getSaisieEnAttenteWithParams(0, 1)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          let badgeNotification = Number(result.totalRecords);
          this.saisieEnAttend = Number(result.totalRecords);
          // total astreintes à valider
          this.ValidationService.getAvaliderAstrainteWithParams(0, 1)
            .pipe(take(1))
            .subscribe({
              next: (result2) => {
                this.badgeNotification = badgeNotification + Number(result2.totalRecords);
                this.astrainteEnAttend = Number(result2.totalRecords);
              },
              error: (error) => {
                console.log('erreur:', error);
              }
            });
        },
        error: (error) => {
          console.log('erreur:', error);
        }
      });
  }

  loadProfile(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.keycloak
        .isLoggedIn()
        .then((isLoggedIn) => {
          if (isLoggedIn) {
            this.keycloak
              .loadUserProfile()
              .then((data) => {
                resolve(data);
              })
              .catch((error) => {
                console.log(error);
                reject(error);
              });
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  addLink() {
    this.links.push(`Link ${this.links.length + 1}`);
  }
  logout() {
    sessionStorage.removeItem('isLoginAction');
    sessionStorage.removeItem('isDRH');
    this.keycloak.logout('');
  }

  modalGererDelegation() {
    this.dialog.open(DelegationModalComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        name: this.name,
        agentId: '',
        idUser: this.userId,
        viewMode: 'user'
      }
    });
  }

  modalGererNotifications() {
    this.matMenuTrigger.closeMenu();
    this.dialog.open(NotificationsModalComponent, {
      maxWidth: '40vw',
      maxHeight: '50vh',
      /*height: '50%',*/
      width: '40%',
      data: {
        name: this.name,
        agentId: '',
        idUser: this.userId,
        viewMode: 'user',
        agent: this.moi
      }
    });
  }

  modalAlarmeConfig() {
    this.dialog.open(AlarmConfigModalComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {}
    });
  }

  redirection_accueil(chartActif?: boolean) {
    let _url = this.router.url.split('?');
    let url = _url[0].replace('/', '');

    if (url == 'accueil') {
      this.chartActif = !this.chartActif;
    } else {
      this.chartActif = true;
    }

    this.router.navigate(['/', 'accueil'], { queryParams: { chartActif: this.chartActif } });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
