import { Component, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ExportDataModalComponent } from './export-data-modal/export-data-modal.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ArianeNavComponent } from 'src/app/layout/ariane-nav/ariane-nav.component';
import { ArchivageManuelModalComponent } from './archivage-manuel-modal/archivage-manuel-modal.component';

import { FormsModule, ReactiveFormsModule, FormGroup, FormBuilder, FormControl } from '@angular/forms'; // Import these modules
import { RestrictedPageComponent } from 'src/app/shared/restricted-page/restricted-page.component';
import { RoleService } from 'src/app/services/role.service';
import { ExportService } from 'src/app/services/export.service';
import { take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CampagnesService } from 'src/app/services/campagnes.service';
import { AlerteModalComponent } from 'src/app/shared/alerte-modal/alerte-modal.component';
import { ValidationModalComponent } from 'src/app/shared/validation-modal/validation-modal.component';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-export-data',
  standalone: true,
  imports: [
    CommonModule,
    DataTableComponent,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ArianeNavComponent,
    RestrictedPageComponent,
    MatDialogModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.scss'],
  providers: [
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' }
    }
  ]
})
export class ExportDataComponent implements OnInit {
  userRoles: string[] = [];

  exportAstr: any = [];
  lengthAstr: number;
  filterValueAstr: string = '';

  exportFiche: any = [];
  lengthFiche: number;
  filterValueFiche: string = '';

  exportControle: any = [];
  lengthControle: number;
  filterValueControle: string = '';
  periodeList: any = [];
  form_filtreControle: FormGroup = this.formBuilder.group({
    filtrePeriode: {}
  });
  val_filtrePeriode: any = {};
  filtrePeriode = new FormControl('');

  companyName: any;
  campagneData: any[] = [];

  // Sert à définir la plage de date initiale
  DATE_OUVERTURE = '2024-08-01';

  boutonArchivageManuelActif: boolean = false;
  campganeNonArchive: boolean = true;
  typeExport: string = 'astre';
  val_ft_export_astr: string = '';
  constructor(
    public dialog: MatDialog,
    private roleService: RoleService,
    private exportService: ExportService,
    private campagneService: CampagnesService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.roleService.getRole().subscribe((roles) => {
      this.userRoles = roles;

      let index = Object.keys(roles).find((val: any) => roles[val] === 'dhesa-admin');
      this.boutonArchivageManuelActif = index != undefined ? true : false;
    });

    this.loadCampagnes();
    this.getListe_Astr();
    this.getListe_Fiche();
    //this.getListe_Controle();
  }

  getListe_Astr() {
    this.exportService
      .exportAstrWithParams(environment.pagination.offset, environment.pagination.limit)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.exportAstr = this.transformData_Astr(result.results);
          this.lengthAstr = result.totalRecords;
        } else {
          console.log('error');
        }
      });
  }

  // lecture des données pour la vue globale
  getListe_Fiche() {
    this.exportService
      .exportFicheWithParams(environment.pagination.offset, environment.pagination.limit)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.exportFiche = this.transformData_Fiche(result.results);
          this.lengthFiche = result.totalRecords;
        } else {
          console.log('error');
        }
      });
  }

  // Récupération des données de contrôle (filtre par période de 3 mois)
  getListe_Controle() {
    this.exportService
      .exportControleWithParams(
        environment.pagination.offset,
        environment.pagination.limit,
        {},
        null,
        this.val_filtrePeriode.dateDebut,
        this.val_filtrePeriode.dateFin
      )
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.exportControle = this.transformData_Controle(result.results);
          this.lengthControle = result.totalRecords;
        } else {
          console.log('error');
        }
      });
  }

  pipe = new DatePipe('fr-FR');
  arianeLabel: string = 'Export des données';
  arianeIcon: string = 'upload_file';

  astrDisplayedColumns: string[] = [
    'NUM Ligne',
    'Organisme',
    'Identifiant',
    'Campagne',
    'Num Emploi',
    'Code Rubrique',
    'Date début',
    'Date fin',
    'Nombre',
    'Code HS',
    'Date début ref',
    'Date fin ref'
  ];

  ficheDisplayColumns: string[] = ['Agent', 'Direction origine', 'Service origine', 'Campagne', 'Actions'];

  controleDisplayedColumns: string[] = [
    'NUM Ligne',
    'Organisme',
    'Identifiant',
    'Campagne',
    'Num Emploi',
    'Code Rubrique',
    'Date début',
    'Date fin',
    'Nombre',
    'Code HS',
    'Date début ref',
    'Date fin ref'
  ];

  cellStyleCss: any = {
    Collaborateur: { 'text-align': 'left', 'padding-left': '10px' },
    'NUM Ligne': { 'text-align': 'center' },
    Organisme: { 'text-align': 'center' },
    Identifiant: { 'text-align': 'center' },
    'Num Emploi': { 'text-align': 'center', 'padding-right': '30px' },
    'Code Rubrique': { 'text-align': 'center', 'padding-right': '30px' },
    Nombre: { 'text-align': 'center', 'padding-right': '25px' },
    'Taux IFSE': { 'text-align': 'center', 'padding-right': '25px' },
    'Code HS': { 'text-align': 'center', 'padding-right': '25px' },
    'Date début': { 'text-align': 'center' },
    'Date fin': { 'text-align': 'center' },
    'Date début ref': { 'text-align': 'center' },
    'Date fin ref': { 'text-align': 'center' }
  };

  openExportDialog() {
    this.dialog.open(ExportDataModalComponent, {
      width: '800px',
      data: { generiqueFiltre: this.val_ft_export_astr, exportParCampagne: true }
    });
  }
  openExportControleDialog() {
    this.dialog.open(ExportDataModalComponent, {
      width: '800px',
      data: {
        generiqueFiltre: null,
        dateRealisationStart: this.val_filtrePeriode.dateDebut,
        dateRealisationEnd: this.val_filtrePeriode.dateFin,
        exportParTrimestre: true
      }
    });
  }
  transformData_Astr(result: any) {
    let data: any[] = [];

    for (let key in result) {
      let dataJ = result[key];
      let _data: any = {};
      _data['NUM Ligne'] = dataJ['numLigne'];
      _data['Organisme'] = dataJ['agentCodeOrganisme'];
      _data['Identifiant'] = dataJ['agentMatricule'];
      _data['Campagne'] = dataJ['nomCampagne'];
      _data['Num Emploi'] = dataJ['codeAffectation'];
      _data['Code Rubrique'] = dataJ['codeRubriquePaye'];
      _data['Date début'] = this.pipe.transform(dataJ?.exportDateDebut, 'd/MM/yyyy');
      _data['Date fin'] = this.pipe.transform(dataJ?.exportDateFin, 'd/MM/yyyy');
      _data['Nombre'] = dataJ?.totalHeures;
      _data['Code HS'] = dataJ?.code;
      _data['Date début ref'] = this.pipe.transform(dataJ?.realisationDateDebut, 'd/MM/yyyy');
      _data['Date fin ref'] = this.pipe.transform(dataJ?.realisationDateFin, 'd/MM/yyyy');
      data.push(_data);
    }
    return data;
  }

  transformData_Controle(result: any) {
    let data: any[] = [];

    for (let key in result) {
      let dataJ = result[key];
      let _data: any = {};
      _data['NUM Ligne'] = dataJ['numLigne'];
      _data['Organisme'] = dataJ['agentCodeOrganisme'];
      _data['Identifiant'] = dataJ['agentMatricule'];
      _data['Campagne'] = dataJ['nomCampagne'];
      _data['Num Emploi'] = dataJ['codeAffectation'];
      _data['Code Rubrique'] = dataJ['codeRubriquePaye'];
      _data['Date début'] = this.pipe.transform(dataJ?.exportDateDebut, 'd/MM/yyyy');
      _data['Date fin'] = this.pipe.transform(dataJ?.exportDateFin, 'd/MM/yyyy');
      _data['Nombre'] = dataJ?.totalHeures;
      _data['Code HS'] = dataJ?.code;
      _data['Date début ref'] = this.pipe.transform(dataJ?.realisationDateDebut, 'd/MM/yyyy');
      _data['Date fin ref'] = this.pipe.transform(dataJ?.realisationDateFin, 'd/MM/yyyy');
      data.push(_data);
    }
    return data;
  }

  transformData_Fiche(result: any) {
    let data: any[] = [];

    for (let key in result) {
      let dataJ = result[key];
      let _data: any = {};

      _data['Agent'] = dataJ['nomPrenomAgent'];
      _data['Direction origine'] = dataJ['directionOrigine'];
      _data['Service origine'] = dataJ['serviceOrigine'];
      _data['Campagne'] = dataJ['nomCampagne'];

      // OBSOLETE : on ne propose un lien de création PDF que pour les campagnes non archivées
      let statut = dataJ?.statutCampagne;
      //if (statut != 'ARCHIVEE') {
      _data['exportData'] = [dataJ['idCampagne'], dataJ['idAgent'], dataJ['nomPrenomAgent']];
      //}

      data.push(_data);
    }
    return data;
  }

  exportPdf(data: any) {
    console.log('exportPDF dans export-data : data = ' + JSON.stringify(data));
    this.exportService
      .exportPdfFiche(data[0], data[1])
      .pipe(take(1))
      .subscribe({
        next: (result: any) => {
          const blob = new Blob([result], { type: 'application/pdf' });

          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          const search = '/';
          const replaceWith = '-';
          const date = new Date().toLocaleDateString('en-GB').split(search).join(replaceWith);
          let nomPrenomAgent = data[2] != null ? data[2].toLowerCase().replace(/\W/g, '_') : '';
          let fileName = `Export_fiche_navette-${nomPrenomAgent}-${date}`;
          link.download = fileName;

          // Append the link to the document
          document.body.appendChild(link);

          // Trigger the click event to start the download
          link.click();

          // Remove the link from the document
          document.body.removeChild(link);
        },
        error: (error) => {}
      });
  }

  creerPdf_Fiche(data: any) {
    this.exportService
      .getPdfFiche(data[0], data[1])
      .pipe(take(1))
      .subscribe({
        next: (result: any) => {
          // code d'état: 200, l'API retourne une valeur null, on affiche une confirmation
          this.dialog.open(ValidationModalComponent, {
            width: '600px',
            data: {
              title: 'Confirmation',
              content: 'Le PDF a été généré avec succès et envoyé à NeoGed.'
            }
          });
        },
        error: (error: any) => {
          console.log('erreur', error);

          let erreur_texte = error.error.detail || error.error || error;

          this.dialog.open(AlerteModalComponent, {
            width: '640px',
            data: {
              title: 'Alerte',
              content: 'Erreur lors de la génération du PDF !',
              status: 'STATUS: ' + error.status,
              detail: 'DETAIL: ' + erreur_texte
            }
          });
        }
      });
  }

  updatePaginationAstr(event: any) {
    if (event) {
      this.exportService
        .exportAstrWithParams(event.offset, event.limit, {}, event.filterValue)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.exportAstr = this.transformData_Astr(result.results);
            this.lengthAstr = result.totalRecords;
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    } else {
      this.exportService
        .exportAstrWithParams(environment.pagination.offset, environment.pagination.limit)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.exportAstr = this.transformData_Astr(result.results);
            this.lengthAstr = result.totalRecords;
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    }
  }

  updatePaginationFiche(event: any) {
    if (event) {
      this.exportService
        .exportFicheWithParams(event.offset, event.limit, {}, event.filterValue)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.exportFiche = this.transformData_Fiche(result.results);
            this.lengthFiche = result.totalRecords;
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    } else {
      this.exportService
        .exportFicheWithParams(environment.pagination.offset, environment.pagination.limit)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.exportFiche = this.transformData_Fiche(result.results);
            this.lengthFiche = result.totalRecords;
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    }
  }

  updatePaginationControle(event: any) {
    if (event) {
      this.exportService
        .exportControleWithParams(event.offset, event.limit, {}, null, this.val_filtrePeriode.dateDebut, this.val_filtrePeriode.dateFin)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.exportControle = this.transformData_Controle(result.results);
            this.lengthControle = result.totalRecords;
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    } else {
      this.exportService
        .exportControleWithParams(
          environment.pagination.offset,
          environment.pagination.limit,
          {},
          null,
          this.val_filtrePeriode.dateDebut,
          this.val_filtrePeriode.dateFin
        )
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.exportControle = this.transformData_Controle(result.results);
            this.lengthControle = result.totalRecords;
          },
          error: (error) => {
            console.log('erreur:', error);
          }
        });
    }
  }

  loadCampagnes() {
    const offset = environment.pagination.offset;
    const limit = 2000;
    this.campagneService
      .getCampagnesWithParams(offset, limit)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.campagneData = result.results;

          let periodes = this.buildPeriodes();
          let premierePeriode = periodes[0];
          this.val_filtrePeriode = premierePeriode;
          this.periodeList = periodes;

          this.getListe_Controle();

          const testedCmp = this.campagneData.filter((item) => item.statut == 'TERMINEE');
          // on initialise à false
          this.campganeNonArchive = false;
          const oldestArchivDate = testedCmp.reduce((oldestDate, currentItem) => {
            const archivageDate = new Date(currentItem.dateArchivage);
            if (!oldestDate || archivageDate < oldestDate) {
              this.campganeNonArchive = true;
              return archivageDate;
            } else {
              // On ne veut pas repasser à false sinon on ne pourra jamais archiver
              // de campagne sauf quand c'est la dernière de la liste qui est archivable !!!
              //this.campganeNonArchive = false;
              return oldestDate;
            }
          }, null);
          const companyHasOldestArchivage = testedCmp.find((item) => {
            const archivageDate = new Date(item.dateArchivage);
            return archivageDate.getTime() === oldestArchivDate.getTime();
          });

          if (companyHasOldestArchivage != undefined) {
            this.companyName = companyHasOldestArchivage?.nom;
          }
        },
        error: (error) => {
          console.log('erreur:', error);
        }
      });
  }
  openArchivageManuel() {
    if (this.campganeNonArchive) {
      this.dialog.open(ArchivageManuelModalComponent, {
        width: '780px',
        data: {
          nomCampagne: this.companyName
        }
      });
    } else {
      this.dialog.open(AlerteModalComponent, {
        width: '640px',
        data: {
          title: 'Alerte',
          content: 'Il n’y a aucune campagne à archiver'
        }
      });
    }
  }
  updateFiltreAstr(value: any) {
    this.filterValueAstr = value.filterValue;
    this.updatePaginationAstr(value);
  }
  updateFiltreFiche(value: any) {
    this.filterValueFiche = value.filterValue;
    this.updatePaginationFiche(value);
  }
  updateFiltreControle(value: any) {
    console.log('updateFiltreControle with value = ' + this.val_filtrePeriode);
    //this.val_filtrePeriode = value;
    // On repart au début à chaque changement de filtre
    // TODO : repartir à la page zéro ???
    this.updatePaginationControle({ offset: 0, limit: environment.pagination.limit });
  }

  onRadioChange(value: any) {
    /*if (value.value=='navette') {
      this.isAst = true;
    } else {
      this.isAst = false;
    }*/
  }

  buildPeriodes() {
    const periodes = [];
    let currentDate = new Date(this.DATE_OUVERTURE);
    const today = new Date();
    while (currentDate <= today) {
      const dateFin = new Date(currentDate);
      dateFin.setMonth(dateFin.getMonth() + 3);
      dateFin.setDate(dateFin.getDate() - 1); // Pour obtenir le dernier jour du mois

      // Vérifier si la date de fin dépasse le mois actuel
      if (dateFin.getMonth() > today.getMonth() && dateFin.getFullYear() >= today.getFullYear()) {
        break;
      }

      const libelle = `${currentDate.toLocaleString('default', { month: 'long' })} ${currentDate.getFullYear()} à ${dateFin.toLocaleString(
        'default',
        { month: 'long' }
      )} ${dateFin.getFullYear()}`;

      const periode = {
        libelle: libelle,
        dateDebut: currentDate.toISOString().split('T')[0],
        dateFin: dateFin.toISOString().split('T')[0]
      };

      periodes.push(periode);

      // Mettre à jour la date de début pour la prochaine période
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return periodes.reverse();
  }
}
