export const environment = {
  production: false,
  apiEndpoint: 'https://dhesa-dev.studia.fr/dhesa/api',
  webService: {
    url: '/dhesa',
    api: '/api',
    server: 'https://dhesa-dev.studia.fr'
  },
  keycloak: {
    url: 'https://sso.telino.net/auth',
    realm: 'DhesaDev',
    clientId: 'dhesa-front'
  },
  pagination: {
    limit: 100,
    offset: 0
  },
  version: '1.14f',
  features: {
    validation_en_saisie: true,
    menu_aide: true
  },
  menu_aide: {
    url: 'https://youtu.be/cBlsWue2Ssc'
  }
};
