<span class="material-symbols-outlined icon icon-titre">{{ arianeIcon }}</span>

<mat-divider class="my-3"></mat-divider>
<mat-radio-group
  [(ngModel)]="typeExport"
  class="ml-50 mb-10"
  aria-label="Choisir une option"
  (change)="onRadioChange($event)"
  [ngModelOptions]="{ standalone: true }"
>
  <mat-radio-button
    class="custom-radio-button"
    [value]="'astre'"
    [ngClass]="{ active: typeExport == 'astre' }"
    [checked]="typeExport == 'astre'"
    ><span style="cursor: pointer">EXPORT DES DONNÉES POUR ASTRE</span></mat-radio-button
  >
  <mat-radio-button [disabled]="false" [value]="'navette'" class="custom-radio-button" [ngClass]="{ active: typeExport == 'navette' }"
    ><span style="cursor: pointer">EXPORT FICHES NAVETTES PAR AGENT</span></mat-radio-button
  >
  <mat-radio-button [disabled]="false" [value]="'controle'" class="custom-radio-button" [ngClass]="{ active: typeExport == 'controle' }"
    ><span style="cursor: pointer">EXPORT DE CONTRÔLE</span></mat-radio-button
  >
</mat-radio-group>
<div style="border-top-style: solid; border-top-color: rgba(0, 0, 0, 0.12); border-top-width: 1px" class="mt-2"></div>

<div class="row mt-3" *ngIf="typeExport == 'astre'">
  <div class="col">
    <mat-form-field class="mr-5" class="field-filtre">
      <mat-label>Filtrer</mat-label>
      <input matInput (keyup)="dataTableAstr.applyFilter($event)" #input />
    </mat-form-field>
  </div>
  <div class="col flex-container end align-items-center">
    <button
      mat-stroked-button
      class="bt-table-defaut bt-table-ajouter mr-5"
      (click)="openArchivageManuel()"
      *ngIf="boutonArchivageManuelActif"
    >
      <mat-icon>exit_to_app</mat-icon>Archivage manuel
    </button>
    <button mat-stroked-button class="bt-table-defaut bt-table-ajouter mr-5" (click)="openExportDialog()">
      <mat-icon>download</mat-icon>Exporter
    </button>
  </div>
</div>

<div>
  <app-data-table
    [style.display]="typeExport == 'astre' ? 'block' : 'none'"
    #dataTableAstr
    [displayedColumns]="astrDisplayedColumns"
    [cellStyleCss]="cellStyleCss"
    [data]="exportAstr"
    [filter]="false"
    [length]="lengthAstr"
    (updatePagination)="updatePaginationAstr($event)"
    (updateFiltre)="updateFiltreAstr($event)"
    [customFilter]="true"
  >
  </app-data-table>
</div>

<div class="row mt-3" *ngIf="typeExport == 'navette'">
  <div class="col">
    <mat-form-field class="mr-5" class="field-filtre">
      <mat-label>Filtrer</mat-label>
      <input matInput (keyup)="dataTableFiche.applyFilter($event)" #input />
    </mat-form-field>
  </div>
</div>

<div>
  <app-data-table
    [style.display]="typeExport == 'navette' ? 'block' : 'none'"
    #dataTableFiche
    [displayedColumns]="ficheDisplayColumns"
    [cellStyleCss]="cellStyleCss"
    [data]="exportFiche"
    [filter]="false"
    [length]="lengthFiche"
    (updatePagination)="updatePaginationFiche($event)"
    (updateFiltre)="updateFiltreFiche($event)"
    [customFilter]="true"
    [export]="true"
    (exporterLigne)="exportPdf($event)"
  >
  </app-data-table>
</div>

<div class="row mt-3" *ngIf="typeExport == 'controle'">
  <div class="col">
    <mat-form-field floatLabel="always" class="field-filtre">
      <mat-label>Filtre par périodes</mat-label>
      <mat-select
        [formControl]="filtrePeriode"
        [(ngModel)]="val_filtrePeriode"
        (selectionChange)="updateFiltreControle($event)"
        placeholder="Période"
      >
        <mat-option *ngFor="let periode of periodeList" [value]="periode">{{ periode.libelle }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col flex-container end align-items-center">
    <button mat-stroked-button class="bt-table-defaut bt-table-ajouter mr-5" (click)="openExportControleDialog()">
      <mat-icon>download</mat-icon>Exporter
    </button>
  </div>
</div>

<div>
  <app-data-table
    [style.display]="typeExport == 'controle' ? 'block' : 'none'"
    #dataTableControle
    [displayedColumns]="controleDisplayedColumns"
    [cellStyleCss]="cellStyleCss"
    [data]="exportControle"
    [filter]="false"
    [length]="lengthControle"
    (updatePagination)="updatePaginationControle($event)"
    [customFilter]="false"
  >
  </app-data-table>
</div>
