import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormBuilder, FormsModule, ReactiveFormsModule, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { BandeauParcourirAstComponent } from 'src/app/shared/bandeau-parcourir-ast/bandeau-parcourir-ast.component';
import { delay, Observable, of, take } from 'rxjs';
import { MatChipsModule } from '@angular/material/chips';
import { AgentsService } from 'src/app/services/agents.service';
import { DateTime } from 'luxon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DirectionService } from 'src/app/services/direction.service';
import { JoursFeriesService } from 'src/app/services/jours-feries.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { FormulaireAstreinteModel, LigneAstreinte } from 'src/app/models/formulaire-astreinte.model';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { CustomLuxonDateAdapter } from 'src/app/shared/utils/custom-luxon-date-adapter';
import { MY_FORMATS } from 'src/app/shared/utils/date-pickr-custom-luxon-format';

@Component({
  selector: 'app-astreinte-form',
  standalone: true,
  imports: [
    BandeauParcourirAstComponent,
    CommonModule,
    MatTableModule,
    MatRadioModule,
    MatIconModule,
    MatCardModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatAutocompleteModule,
    NgxMaterialTimepickerModule,
    MatSnackBarModule
  ],
  templateUrl: './astreinte-form.component.html',
  styleUrls: ['./astreinte-form.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: DateAdapter, useClass: CustomLuxonDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class AstreinteFormComponent {
  agentsList: any = [];
  selectedAgents: any[] = [];
  agentFormControl = new FormControl();
  filteredOptions: any = [];
  directionList$!: Observable<any>;
  directionId_val: string;
  totalDureeFormMin: number;
  totalDureeFormStr: string = '00:00';
  totalJourAstreinte: number;

  /*minDate: any;
  maxDate: any;
  minDateFinA: any[] = [];*/
  minDateDebut: any[] = [];
  maxDateDebut: any[] = [];
  minDateFin: any[] = [];
  maxDateFin: any[] = [];

  nombre: number | null = null;
  /**
   * Est-ce que le nombre est en lecture seule (calculé automatiquement)
   */
  isNombreRO: boolean = true;
  dateFinRO: boolean = false;
  dateDebutCampagne: any = '';
  dateFinCampagne: any = '';
  erreurChoixAgentCadre: boolean = false;
  erreurAgentsNonEligibles: boolean = false;
  listEvent: any = [];
  val_evenement: string = '';
  formAstreinte: FormGroup;
  delayObserable: Observable<any> = new Observable();
  jourFerierList: any = [];
  @Input() currentIndex = 0;
  @Input() typeHS: string = 'HS_POUR_DIRECTION_ORIGINE';
  @Input() infosFormulaires: FormulaireAstreinteModel[] = [];

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private agentsService: AgentsService,
    private directionService: DirectionService,
    public dialogRef: MatDialogRef<AstreinteFormComponent>,
    private cdr: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    public joursFeriesService: JoursFeriesService
  ) {}

  ngOnInit() {
    this.formAstreinte = this.formBuilder.group({
      type: [null, Validators.required],
      //directionOrganisatriceId: [null, Validators.required],
      hoursFormArray: this.formBuilder.array([
        this.formBuilder.group({
          date_debut: [null, Validators.required],
          date_fin: [null, Validators.required],
          nombre: [null, [Validators.required, Validators.min(1)]]
        })
      ])
    });
    this.getJourFerierList();
    this.loadAgents();
    this.verifCampagneActuelle();
    this.directionList$ = this.directionService.getAllDirections();

    // to evoid ExpressionChangedAfterItHasBeenCheckedError
    of(null)
      .pipe(delay(0))
      .subscribe(() => {
        this.loadFormulaire(this.currentIndex);
      });
  }

  agents: any[] = [];

  //formAstreinte: FormGroup;

  loadFormulaire(idx: number) {
    // On recharge les valeurs du formulaire depuis la page correspondante du tableau de données
    // init premier formulaire
    if (this.infosFormulaires.length <= 0) {
      this.infosFormulaires[0] = this.getNewBlankInfosFormulaire();
    }
    // Le formulaire du bon index existe déjà
    if (idx < this.infosFormulaires.length) {
      let currentForm: FormulaireAstreinteModel = this.infosFormulaires[idx];

      let type = currentForm.type;
      this.getFormControlByName('type').setValue(type);
      if (type == 'SEMAINE' || type == 'WEEKEND' || type == 'SAMEDI' || type == 'DIMANCHE_FERIE') {
        this.dateFinRO = true;
      }

      this.changeType(null);

      this.selectedAgents = currentForm.agents;
      this.loadFormHoraires(currentForm.lignes);
    } else {
      console.log("On ne peut pas charger le formulaire d'idx " + idx + ", il n'existe pas...");
    }
  }

  storeFormulaire(idx: number) {
    const formToJson = this.formAstreinte.value;
    if (idx < this.infosFormulaires.length) {
      let currentForm: FormulaireAstreinteModel = this.infosFormulaires[idx];

      currentForm.type = formToJson.type;
      currentForm.directionOrganisatriceId = null;

      currentForm.agents = this.selectedAgents;
      currentForm.lignes = [];
      if (formToJson.hoursFormArray) {
        for (let currentHour of formToJson.hoursFormArray) {
          currentForm.lignes.push({
            date_debut: currentHour.date_debut,
            date_fin: currentHour.date_fin,
            nombre: currentHour.nombre
          });
        }
      }
    }
  }

  loadFormHoraires(horaires: LigneAstreinte[]) {
    let formHoraires = this.getFormArrayByName('hoursFormArray');
    // on vire toutes les heures...
    for (let i = formHoraires.length; i > 0; i--) {
      formHoraires.removeAt(i - 1);
    }
    // ... pour les remplacer par les nouvelles

    if (horaires.length > 0) {
      for (let currentHoraire of horaires) {
        this.hours.push(
          this.formBuilder.group({
            date_debut: [currentHoraire.date_debut, Validators.required],
            date_fin: [currentHoraire.date_fin, Validators.required],
            nombre: [currentHoraire.nombre, [Validators.required, Validators.min(1)]]
          })
        );
      }
      this.recomputeTotalHS();
    }
    // si les heures sont vides, on remet une ligne vide prête à être saisie
    if (horaires.length <= 0) {
      this.hours.push(
        this.formBuilder.group({
          date_debut: [null, Validators.required],
          date_fin: [null, Validators.required],
          nombre: [null, [Validators.required, Validators.min(1)]]
        })
      );
    }
  }

  recomputeTotalHS() {
    if (this.hours && this.hours.length > 0) {
      let cumulJour = 0;

      const formToJson = this.formAstreinte.value;
      for (let i = 0; i < formToJson.hoursFormArray.length; i++) {
        let currentHour = formToJson.hoursFormArray[i];

        let date_debut = currentHour.date_debut;
        let date_fin = currentHour.date_fin;

        // désactivation des dates du calendrier
        this.maxDateDebut[i] = this.dateFinCampagne;
        this.minDateDebut[i] = this.dateDebutCampagne;
        this.minDateFin[i] = date_debut;
        this.maxDateFin[i] = this.dateFinCampagne;

        let nombre: any = currentHour?.nombre || 0;
        nombre = nombre == null || nombre == '' ? this.calculNombre(date_debut, date_fin) : Number(nombre);
        cumulJour += Number(nombre);
      }
      this.totalJourAstreinte = cumulJour;
    }
  }

  getNewBlankInfosFormulaire() {
    return {
      type: '',
      directionOrganisatriceId: null,
      lignes: [],
      agents: []
    };
  }

  /**
   * Permet de récupérer les infos des formulaires.
   * Ne pas oublier de récupérer les données actuellement affichées dans le formulaire
   */
  getCurentInfosFormulaires(): FormulaireAstreinteModel[] {
    // Transfert du formulaire actuellement affiché vers le FormulaireHSModel
    this.storeFormulaire(this.currentIndex);
    return this.infosFormulaires;
  }

  loadAgents() {
    /**
     * Seulement les agents de ma direction
     */
    this.agentsService
      .getMaDirectionAgents()
      .pipe(take(1))
      .subscribe((data) => {
        if (data /*&& data.results*/) {
          this.agentsList = data /*.results*/;
          this.filteredOptions = [...this.agentsList];
        } else {
          console.log('error'); //obselète
        }
      });
  }

  filter(event: any): void {
    const value = event.target.value;
    if (typeof value !== 'string') {
      this.filteredOptions = [];
      return;
    }
    const filterValue = value.toLowerCase().split(' ').join('');
    this.filteredOptions = this.agentsList.filter((agent: any) =>
      (agent.nom + agent.prenom + '|' + agent.direction?.libelle + '|' + agent.serviceFonctionnel?.libelle)
        .toLowerCase()
        .split(' ')
        .join('')
        .includes(filterValue)
    );
  }

  displayFn(agent: any): string {
    return agent ? `${agent.nom} ${agent.prenom}` : '';
  }

  // recherche la ligne data correspondant à l'id
  searchAgentIndex(id: number) {
    let data = this.agentsList;
    let row = Object.keys(data).find((k) => data[k].id === id);
    let index = Number(row);
    return index;
  }

  delete_agent(agent: string) {
    const index = this.selectedAgents.indexOf(agent);
    if (index >= 0) {
      this.selectedAgents.splice(index, 1);
    }
  }

  getJourFerierList() {
    this.joursFeriesService
      .getAllJoursFeries()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          data.forEach((data_a: any) => {
            data_a['dateJourFerier_fmt'] = DateTime.fromISO(data_a.dateJourFerier).toFormat('yyyy-MM-dd');
          });
          this.jourFerierList = data;
        }
      });
  }

  get hours(): FormArray {
    return this.formAstreinte.get('hoursFormArray') as FormArray;
  }

  getFormControlByName(ctrlName: string): FormControl {
    return this.formAstreinte.get(ctrlName) as FormControl;
  }
  getFormArrayByName(ctrlName: string): FormArray {
    return this.formAstreinte.get(ctrlName) as FormArray;
  }

  //add_inputs(date?: string, heure_debut?: string, heure_fin?: string, duree?: string) {
  add_inputs() {
    this.hours.push(
      this.formBuilder.group({
        date_debut: [null, Validators.required],
        date_fin: [null, Validators.required],
        nombre: [null, [Validators.required, Validators.min(1)]]
      })
    );

    this.maxDateDebut.push(this.dateFinCampagne);
    this.minDateDebut.push(this.dateDebutCampagne);
    this.minDateFin.push(null);
    this.maxDateFin.push(this.dateFinCampagne);
  }

  remove_inputs(index: number) {
    if (this.hours.length > 1) {
      this.hours.removeAt(index);
    } else {
      this.hours.patchValue([{ date_debut: null, date_fin: null, nombre: null }]);
    }
    this.calculNombreTotal();

    this.minDateFin.pop();
    this.maxDateFin.pop();
    this.maxDateDebut.pop();
    this.minDateDebut.pop();

    if (index == 0) this.verifCampagneActuelle();
  }

  add_agent(event: any) {
    this.erreurChoixAgentCadre = false;
    if (event) {
      let selectedAgent = event.option.value;
      const agents = this.agentsList.filter((x: { id: string }) => x.id == selectedAgent.id);
      if (agents && agents.length > 0) {
        const selectedAgent = agents[0];
        if (selectedAgent && !this.selectedAgents.includes(selectedAgent)) this.selectedAgents.push(selectedAgent);
      }
      this.agentFormControl.setValue(null);
      this.filteredOptions = [...this.agentsList];
    }
  }

  // calcul de la différence des dates
  calculNombre(date_debut: any, date_fin: any) {
    let type = this.formAstreinte.controls['type'].value;

    // si type=SEMAINE ou WEEKEND nombre=1
    if (type == 'SEMAINE' || type == 'WEEKEND') {
      return 0;
      // calcul du nombre
    } else {
      let date_debut_dt = DateTime.fromJSDate(date_debut);
      let date_fin_dt = DateTime.fromJSDate(date_fin);
      if (date_debut_dt.isValid && date_fin_dt.isValid) {
        let diff_days = date_fin_dt.diff(date_debut_dt, 'days').days;
        return diff_days;
      }
    }
    return null;
  }

  // changement manuel de la date
  /*changeDateAstreinte(event: any, i: number) {
    var id = event.targetElement.id;
    var val = event.target.value;

    //console.log("val: " + val + " - id: " + id);
    let type = this.formAstreinte.controls['type'].value;

    if (id == 'date_debut') {
      let date_deb = DateTime.fromJSDate(val);
      //console.log("date_deb", date_deb);

      let nb_jour = 1;

      if (type == 'DIMANCHE_FERIE' || type == 'SAMEDI') {
        nb_jour = 0;
        let date_deb_f = new Date(date_deb.toFormat('yyyy-MM-dd'));
        this.hours.at(i).get('date_fin')?.patchValue(date_deb_f);
      } else if (type == 'SEMAINE') {
        nb_jour = 4;
        let date_deb_f = new Date(date_deb.plus({ days: 4 }).toFormat('yyyy-MM-dd'));
        this.hours.at(i).get('date_fin')?.patchValue(date_deb_f);
      }

      if (date_deb.isValid) {
        let minDateFin = date_deb.plus({ days: nb_jour }).toFormat('yyyy-MM-dd');
        this.minDateFinA[i] = new Date(minDateFin);
        //console.log("minDateFin: " + minDateFin);
      }
    } else if (id == 'date_fin') {
      let date_fin = DateTime.fromJSDate(val);

      if (type == 'DIMANCHE_FERIE' || type == 'SAMEDI') {
        //this.formAstreinte.controls["hoursFormArray"].setValue("");
        let date_fin_f = new Date(date_fin.toFormat('yyyy-MM-dd'));
        this.hours.at(i).get('date_debut')?.patchValue(date_fin_f);
      } else if (type == 'SEMAINE') {
        let date_fin_f = new Date(date_fin.plus({ days: -4 }).toFormat('yyyy-MM-dd'));
        this.hours.at(i).get('date_debut')?.patchValue(date_fin_f);
      }

      //
    }
  }*/
  // changement manuel de la date
  changeDateAstreinte(event: any, i: number) {
    let id = event.targetElement.id;
    let val = event.target.value;

    let type = this.formAstreinte.controls['type'].value;

    if (id == 'date_debut') {
      let date_deb = val as DateTime;

      if (type == 'DIMANCHE_FERIE' || type == 'SAMEDI') {
        let date_deb_f = date_deb;
        this.hours.at(i).get('date_fin')?.patchValue(date_deb_f);
      } else if (type == 'SEMAINE') {
        let date_deb_f = date_deb.plus({ days: 6 }); /*.toFormat('yyyy-MM-dd')*/
        this.hours.at(i).get('date_fin')?.patchValue(date_deb_f);
      } else if (type == 'WEEKEND') {
        let date_deb_f = date_deb.plus({ days: 1 }); /*.toFormat('yyyy-MM-dd')*/
        this.hours.at(i).get('date_fin')?.patchValue(date_deb_f);
      }

      if (date_deb.isValid) {
        let minDateFin = date_deb./*plus({ days: 1 }).*/ toFormat('yyyy-MM-dd');
        this.minDateFin[i] = new Date(minDateFin);
      }
    }

    if (id == 'date_fin') {
      let date_fin = val as DateTime;
      if (type == 'DIMANCHE_FERIE' || type == 'SAMEDI') {
        let date_fin_f = date_fin.toFormat('yyyy-MM-dd');
        this.hours.at(i).get('date_debut')?.patchValue(date_fin_f);
      } else if (type == 'SEMAINE') {
        let date_fin_f = date_fin.plus({ days: -6 }); /*.toFormat('yyyy-MM-dd')*/
        this.hours.at(i).get('date_debut')?.patchValue(date_fin_f);
      } else if (type == 'WEEKEND') {
        let date_fin_f = date_fin.plus({ days: -1 });
        this.hours.at(i).get('date_debut')?.patchValue(date_fin_f);
      }

      if (date_fin.isValid) {
        let maxDateDebut = date_fin /*.plus({ days: -1 })*/
          .toFormat('yyyy-MM-dd');
        this.maxDateDebut[i] = new Date(maxDateDebut);
      }
    }

    // recalcul du nombre automatique
    let date_debut = this.hours.at(i).get('date_debut')?.value;
    let date_fin = this.hours.at(i).get('date_fin')?.value;

    if (date_debut && date_fin) {
      let dateDebJS = date_debut.toJSDate();
      let dateFinJS = date_fin.toJSDate();

      let diff = this.calculNombre(dateDebJS, dateFinJS);
      if (diff != null) {
        diff = Math.round(diff) + 1; // +1 si dates identiques
        this.hours.at(i).get('nombre')?.patchValue(diff);
      }
    }

    this.calculNombreTotal();
    this.hours.at(i).get('date_debut')?.updateValueAndValidity();
    this.hours.at(i).get('date_fin')?.updateValueAndValidity();
    this.hours.at(i).get('nombre')?.updateValueAndValidity();
  }

  // grise les jours du calendrier suivant les dates de la campagne en cours
  verifCampagneActuelle() {
    let campagneSession: any = sessionStorage.getItem('campagneActuelle');
    let campagneActuelle = JSON.parse(campagneSession) || null;

    if (campagneActuelle) {
      let dateFin = campagneActuelle.dateFin;

      let day_fin = DateTime.fromFormat(dateFin, 'dd/MM/yyyy').setLocale('fr').day;
      let minDateDebut = DateTime.fromFormat(dateFin, 'dd/MM/yyyy').plus({ month: -3 }).set({ day: 1 }).toFormat('yyyy-MM-dd');

      this.dateDebutCampagne = new Date(minDateDebut);

      if (this.minDateDebut.length == 0) this.minDateDebut.push(new Date(minDateDebut));
      if (this.minDateFin.length == 0) this.minDateFin.push(new Date(minDateDebut));

      let maxDateFin = DateTime.fromFormat(dateFin, 'dd/MM/yyyy')
        .plus({ month: 1 })
        .set({ day: 1 })
        .plus({ day: -1 })
        .toFormat('yyyy-MM-dd');

      this.dateFinCampagne = new Date(maxDateFin);

      if (this.maxDateFin.length == 0) this.maxDateFin.push(new Date(maxDateFin));
      if (this.maxDateDebut.length == 0) this.maxDateDebut.push(new Date(maxDateFin));
    }
  }

  dateDebutFilter = (d: DateTime | null): boolean => {
    return true;
  };

  dateFinFilter = (d: DateTime | null): boolean => {
    return true;
  };

  // si choix du type on filtre les dates des calendriers
  changeType(event: any) {
    let type = event?.value;

    if (event == null && type == undefined) {
      type = this.formAstreinte.controls['type'].value;
    }

    this.isNombreRO = true;
    this.dateFinRO = false;

    if (type) {
      // efface les saisies des heures
      //if (type == 'SEMAINE' || type == 'DIMANCHE_FERIE' || type == 'SAMEDI' || type == 'JOUR' || type == 'WEEKEND') {      }
      this.hours.clear();
      this.minDateDebut = [];
      this.maxDateDebut = [];
      this.minDateFin = [];
      this.maxDateFin = [];
      this.add_inputs();

      // Choix libre du Lundi au Vendredi sauf jours fériés
      if (type == 'JOUR') {
        this.dateDebutFilter = (d: DateTime | null): boolean => {
          const day = d ? d.weekday : -1;
          let d_fmt = d?.toFormat('yyyy-MM-dd');
          let jourFerie = this.jourFerierList.find((jf: any) => jf.dateJourFerier_fmt == d_fmt);

          return day != 6 && day != 7 && !jourFerie;
        };
        this.dateFinFilter = this.dateDebutFilter;
        this.isNombreRO = true;
        this.dateFinRO = false;

        // Pour les Nuits les jours fériés sont possibles
      } else if (type == 'NUIT') {
        this.dateDebutFilter = (d: DateTime | null): boolean => {
          const day = d ? d.weekday : -1;
          let d_fmt = d?.toFormat('yyyy-MM-dd');
          //let jourFerie = this.jourFerierList.find((jf: any) => jf.dateJourFerier_fmt == d_fmt);

          return day != 6 && day != 7 /*&& !jourFerie*/;
        };
        this.dateFinFilter = this.dateDebutFilter;
        this.isNombreRO = true;
        this.dateFinRO = false;
      }

      // date début : on peut choisir seulement un Lundi pour la date de début
      // date fin : positionnement du Dimanche de la même semaine
      // nombre = 5
      else if (type == 'SEMAINE') {
        this.dateDebutFilter = (d: DateTime | null): boolean => {
          const day = d ? d.weekday : -1;

          // On ne veut que les Lundi => d = 1
          return day == 1;
        };
        this.dateFinFilter = (d: DateTime | null): boolean => {
          const day = d ? d.weekday : -1;
          // On ne veut que les Dimanche => d = 7
          return day == 7;
        };

        this.isNombreRO = true;
        this.dateFinRO = true;
      } else if (type == 'WEEKEND') {
        this.dateDebutFilter = (d: DateTime | null): boolean => {
          const day = d ? d.weekday : -1;
          // On ne veut que les Samedi => d = 6
          return day == 6;
        };
        this.dateFinFilter = (d: DateTime | null): boolean => {
          const day = d ? d.weekday : -1;
          // On ne veut que les Dimanche => d = 7
          return day == 7;
        };

        this.isNombreRO = true;
        this.dateFinRO = true;
      }

      // date début : on ne peut choisir qu’un dimanche
      // date fin : le même dimanche que la date de début
      // nombre = 1
      else if (type == 'DIMANCHE_FERIE') {
        this.dateDebutFilter = (d: DateTime | null): boolean => {
          const day = d ? d.weekday : -1;
          let d_fmt = d?.toFormat('yyyy-MM-dd');

          let jourFerie = this.jourFerierList.find((jf: any) => jf.dateJourFerier_fmt == d_fmt);
          // on ne veut que les Dimanche et les jours fériés
          return day == 7 || jourFerie;
        };
        this.dateFinFilter = this.dateDebutFilter;

        this.isNombreRO = true;
        this.dateFinRO = true;
      }

      // date début : on ne peut choisir qu’un samedi
      // date fin : le même samedi que la date de début
      else if (type == 'SAMEDI') {
        this.dateDebutFilter = (d: DateTime | null): boolean => {
          const day = d ? d.weekday : -1;
          // Temporaire : on ne veut que les Samedi
          return day == 6;
        };
        this.dateFinFilter = this.dateDebutFilter;

        this.isNombreRO = true;
        this.dateFinRO = true;
      } else {
        this.dateDebutFilter = (d: DateTime | null): boolean => {
          return true;
        };
        this.dateFinFilter = (d: DateTime | null): boolean => {
          return true;
        };

        this.isNombreRO = true;
        this.dateFinRO = false;
      }

      this.calculNombreTotal();
    }
  }

  calculNombreTotal() {
    if (this.hours && this.hours.length > 0) {
      let cumulJour = 0;

      const formToJson = this.formAstreinte.value;
      for (const currentHour of formToJson.hoursFormArray) {
        let date_debut = currentHour.date_debut;
        let date_fin = currentHour.date_fin;
        let nombre: any = currentHour?.nombre || 0;
        nombre = nombre == null || nombre == '' ? this.calculNombre(date_debut, date_fin) : Number(nombre);
        cumulJour += Number(nombre);
      }

      this.totalJourAstreinte = cumulJour;
    }
  }

  // changement manuel du nombre
  changeNombre($event: any, i: number) {
    this.calculNombreTotal();
  }

  /**
   * Bandeau : page suivante
   */
  formulaireSuivant() {
    if (this.currentIndex < this.infosFormulaires.length - 1) {
      this.storeFormulaire(this.currentIndex);
      this.currentIndex++;
      this.loadFormulaire(this.currentIndex);
    }
  }

  /**
   * Bandeau : page précédente
   */
  formulairePrecedent() {
    if (this.currentIndex > 0) {
      this.storeFormulaire(this.currentIndex);
      this.currentIndex--;
      this.loadFormulaire(this.currentIndex);
    }
  }

  /**
   * Bandeau : nouveau formulaire
   */
  formulaireNew() {
    // ajout nouveau formulaire, et on y va directement
    this.infosFormulaires.push(this.getNewBlankInfosFormulaire());
    this.storeFormulaire(this.currentIndex);
    this.currentIndex = this.infosFormulaires.length - 1;
    this.loadFormulaire(this.currentIndex);
  }

  /**
   * Bandeau : enlever formulaire
   */
  formulaireDelete() {
    if (this.infosFormulaires.length > 0) {
      // pas besoin de store, on va supprimer ce formulaire
      this.infosFormulaires.splice(this.currentIndex, 1);
      // passage page précédente
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
      // sinon on était déjà à la première page, l'index est déjà ok
      this.loadFormulaire(this.currentIndex);
    }
  }

  /**
   * Formulaire entièrement valide ?
   */
  isFormulaireValid(): boolean {
    return this.formAstreinte.valid && this.selectedAgents && this.selectedAgents.length > 0;
  }
}
