import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { CommonModule, DatePipe, NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { DataTableComponent } from 'src/app/shared/data-table/data-table.component';
import { MatStepperModule } from '@angular/material/stepper';
@Component({
  selector: 'app-astreinte-synthese',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatDialogModule,
    MatTableModule,
    DataTableComponent,
    NgFor,
    NgIf,
    MatStepperModule
  ],
  templateUrl: './astreinte-synthese.component.html',
  styleUrls: ['./astreinte-synthese.component.scss'],
  providers: [DatePipe]
})
export class AstreinteSyntheseComponent {
  dialogRef: any;
  dataTable_HS: any[] = [];
  dataSource: any;
  showSpinner: boolean | undefined;
  dataJSON_HS: any[] = [];
  pipe: any;
  @Input() data: any;
  @Output() closeModal = new EventEmitter();
  constructor(private detectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.data) {
      console.log('data = ', this.data);
      console.log('data = ', this.data);
      this.dataSource = this.remapData(this.data);
      console.log('dataSource = ' + JSON.stringify(this.dataSource));
      console.log('dataSource = ' + JSON.stringify(this.dataSource));
      this.detectorRef.markForCheck();
    }
  }

  displayedColumns: string[] = ['Nom', 'Prénom', 'Direction origine', 'Service origine', 'Total jours', 'Actions'];

  subTableDisplayedColumns: string[] = ['Type', 'Date début', 'Date fin', 'Nombre'];

  hourCellStyleCss: any = {
    Statut: { 'text-align': 'center' },
    'Date saisie': { 'text-align': 'center' }
  };
  hourHeaderStyleCssSub: any = {
    'Date début': { 'text-align': 'center' },
    'Date fin': { 'text-align': 'center' },
    Nombre: { 'text-align': 'center' }
  };

  hourCellStyleCssSub: any = {
    Date: { 'text-align': 'center' },
    'Date début': { 'text-align': 'center' },
    'Date fin': { 'text-align': 'center' },
    Nombre: { 'text-align': 'center' }
  };

  remapData(rawData: any[]): any[] {
    let res: any[] = [];

    rawData.forEach((elem: any) => {
      let horairesList: any[] = [];
      let jourTotaleAgent: number = 0;
      // On liste tous les horaires avec le motif (1 motif pour N horaires)
      elem.infos_hs?.forEach((elemHS: any) => {
        elemHS.horaires?.forEach((elemHoraire: any) => {
          let currentHoraireLine = {
            Type: elemHS.type,
            Motif: elemHS.motif,
            'Date début': elemHoraire.date_debut.toFormat('dd/MM/yyyy'),
            'Date fin': elemHoraire.date_fin.toFormat('dd/MM/yyyy'),
            Nombre: elemHoraire.nombre
          };

          horairesList.push(currentHoraireLine);
          jourTotaleAgent += Number(elemHoraire.nombre);
        });
      });

      let currentAgentLine = {
        Nom: elem.agent.nom,
        Prénom: elem.agent.prenom,
        'Direction origine': elem.agent.direction?.libelle,
        'Service origine': elem.agent.serviceFonctionnel?.libelle,
        'Total jours': jourTotaleAgent,
        subTable: horairesList
      };

      res.push(currentAgentLine);
    });

    return res;
  }
}
