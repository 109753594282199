export const environment = {
  production: false,
  apiEndpoint: 'https://dhesa-dev.studia.fr/dhesa/api',
  webService: {
    url: '/dhesa',
    api: '/api',
    server: 'https://dhesa-dev.studia.fr'
    //server: 'http://localhost:8080'
  },
  keycloak: {
    url: 'https://sso.telino.net/auth',
    realm: 'DhesaDev',
    clientId: 'dhesa-front'
  },
  pagination: {
    limit: 25,
    offset: 0
  },
  version: '1.18',
  features: {
    validation_en_saisie: true,
    menu_aide: true
  },
  menu_aide: {
    url: 'https://youtu.be/cBlsWue2Ssc'
  },
  logo_url: 'assets/logos/logo-studia.png',
  titre_app: 'PORTAIL DE SAISIES DES HEURES<br/> COMPLÉMENTAIRES / SUPPLÉMENTAIRES / ASTREINTES'
};
