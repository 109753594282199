<div class="sticky">
  <div class="header-flex">
    <div class="flex-gauche">
      <img class="img-logo" src="{{ appLogo }}" alt="" (click)="redirection_accueil()" />
      <div class="head-text" [innerHTML]="appTitre">&nbsp;</div>
    </div>

    <div class="flex-droite">
      <mat-icon
        matTooltip="Saisies HS/Astreintes en attente de validation"
        *ngIf="boutonNotifActif"
        [matBadge]="badgeNotification"
        [matMenuTriggerFor]="menuNotification"
        matBadgeColor="warn"
        class="icon-helper hidden-xs notification yellow"
        aria-hidden="false"
        style="cursor: pointer"
        >notifications</mat-icon
      >
      <div>
        <mat-menu #menuNotification="matMenu" class="connexionMenu border-yellow" style="width: 500px !important">
          <div style="border-top: 2px solid #ffc107 !important; width: auto"></div>

          <div class="m-3" style="color: #758589; font-size: 14px; font-weight: 500">
            Vous avez <span style="color: #f44336">{{ badgeNotification }}</span> saisies HS/Astreintes à valider
          </div>
          <div class="mb-15" style="margin-left: 40px; font-size: 14px">
            <a (click)="redirectNotification()">
              >
              <span style="font-weight: bold"> {{ saisieEnAttend }}</span> HS en attente de validation</a
            ><br />

            <a (click)="redirectNotificationAst()">
              > <span style="font-weight: bold">{{ astrainteEnAttend }}</span> Astreintes en attente de validation</a
            >
          </div>
          <div style="border-bottom: 2px solid #ffc107 !important; width: auto"></div>
        </mat-menu>
        <button type="button" mat-button class="bouton-chart" (click)="redirection_accueil(true)" matTooltip="Vue graphique">
          <mat-icon class="icon-chart">show_chart</mat-icon>
        </button>
        <a class="button-aide" color="primary" *ngIf="isDisplayHelp" target="_blank" href="{{ helpUrl }}" title="Ouvrir l'aide">
          <mat-icon class="material-symbols-outlined icon-aide">help_outline</mat-icon>
        </a>
        <button
          mat-raised-button
          color="primary"
          [matMenuTriggerFor]="menu"
          #menuTrigger="matMenuTrigger"
          class="button-helper user-button"
          color="primary"
        >
          <mat-icon class="material-symbols-outlined icon-compte">account_circle</mat-icon>
          <div class="texte-utilisateur">{{ name }}</div>
          <mat-menu #menu="matMenu" class="connexionMenu">
            <div class="connexion-titre">
              Dernière connexion le {{ (this.currentAgent$ | async)?.dateDerniereConnexion | date: 'dd/MM/yyyy à HH:mm' }} <br />
              Profil applicatif : {{ roleApplicatif }}
            </div>

            <div class="div-bouton" (click)="$event.stopPropagation()">
              <div class="div-bt-element div-bt-element-1" (click)="modalGererDelegation()">
                <button type="button" class="bt-deleguer">
                  <mat-icon class="deleguer-icon"> supervisor_account </mat-icon> Gérer mes délégations
                </button>
              </div>
              <div class="div-bt-element div-bt-element-1" (click)="modalGererNotifications()">
                <button type="button" class="bt-deleguer">
                  <mat-icon class="deleguer-icon"> email </mat-icon> Gérer mes notifications
                </button>
              </div>

              <div class="div-bt-element-dcn div-bt-element-3">
                <button type="button" class="bt-deconnexion" (click)="logout()">
                  <mat-icon class="logout-icon"> power_settings_new </mat-icon> Se déconnecter
                </button>
              </div>
              <div class="text-align-left ml-10" style="font-size: 12px; color: gray; pointer-events: none">V{{ version }}</div>
            </div>
          </mat-menu>
        </button>
      </div>
    </div>
  </div>
</div>
